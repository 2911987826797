import React, { Component } from "react";

import styled from "styled-components";
import { theme } from "modules/Theme";
import Label from "components/Label";

export const InputDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 4px;
  color: ${theme.colors.medDarkGrey};
`;

export const StyledInput = styled.input`
  background: ${theme.colors.white};
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  margin-top: ${p => (p.hasLabel ? "5px" : "0px")};
  width: 100%;
  font-size: 16px;
  ${props => props.extraStyle};
  -webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`;

export const InputError = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #c12119;
  margin-top: 5px;
`;

export const InputWarning = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #ef9f09;
  margin-top: 5px;
`;

class Input extends Component {
  render() {
    return (
      <div style={{ marginRight: this.props.marginRight ? "20px" : "0px" }}>
        {this.props.label && <Label>{this.props.label}</Label>}
        {this.props.description && (
          <InputDescription>{this.props.description}</InputDescription>
        )}
        <StyledInput
          type={this.props.type || "text"}
          style={{ border: this.props.error && "1px solid #c12119" }}
          onChange={this.props.onChange}
          onKeyPress={this.props.onKeyPress}
          value={this.props.value}
          placeholder={this.props.placeholder}
          extraStyle={this.props.extraStyle}
          hasLabel={this.props.label}
        />
        {this.props.error && <InputError>{this.props.error}</InputError>}
        {this.props.warning && (
          <InputWarning>{this.props.warning}</InputWarning>
        )}
      </div>
    );
  }
}

export default Input;
