import React from "react";
import styled, { css } from "styled-components";

const SkeletonLoader = ({ height, width, radius, className, dark, style }) => (
  <SkeletonDiv
    className={className}
    radius={radius}
    height={height}
    width={width}
    style={style}
    dark={dark}
  />
);

export default SkeletonLoader;

const SkeletonDiv = styled.div`
  max-width: 100%;
  min-width: ${p => p.width || "100%"};
  min-height: ${p => p.height || "18px"};
  width: ${p => p.width || "100%"};
  height: ${p => p.height || "18px"};
  border-radius: ${p => p.radius || "4px"};
  background: #d4d4d4;
  background: ${props =>
    props.translucent
      ? css`linear-gradient(-90deg, #C1C1C1 0%, #F8F8F8 50%, #C1C1C1 100%)`
      : css`linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)`};
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
