import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import MDSpinner from "react-md-spinner";
import arrow from "images/angle-arrow-down.svg";
import checkIcon from "images/check-green.svg";
import { Row } from "components/Layout";
import Button from "components/Button";
import Hint from "components/Hint";
import { get } from "lodash";
// import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/froala_editor.pkgd.min.css";
// import FroalaEditor from "react-froala-wysiwyg";

class Description extends Component {
  state = {
    showOriginal: false,
    showNew: true,
    tab: "preview",
    editableDescription: ""
  };

  showEditableDescription = () => {
    this.setState({
      tab: "current",
      editableDescription: get(this.props, ["description", "current"])
    });
  };

  editInShopify = () => {
    window.open(
      `https://${this.props.storeUrl}/admin/products/${this.props.description.product_id}`
    );
  };

  render() {
    const {
      description,
      fixed,
      fetching,
      hideDescription,
      updateDescription,
      fetchDescription
    } = this.props;

    const visibleDescription = get(description, this.state.tab);

    return (
      <Wrapper>
        <Whatever>
          <BackRow>
            <BackTextRow onClick={hideDescription}>
              <BackArrow src={arrow} />
              <div>Back To Products</div>
            </BackTextRow>
            {!fetching && fixed && (
              <Row>
                <FixedText>
                  {this.props.isUpdatingDescription
                    ? "Updating..."
                    : "Product Updated In Shopify"}
                </FixedText>
                <CheckIcon src={checkIcon} />
              </Row>
            )}
          </BackRow>

          <TabsContainer>
            <MainTabsRow>
              <TabRow
                active={this.state.tab === "preview"}
                onClick={() => this.setState({ tab: "preview" })}
              >
                <Tab>Preview</Tab>
                <Hint hint="The newly generated description. To add this description to your product in Shopify, click the 'Update' button." />
              </TabRow>

              <TabRow
                active={this.state.tab === "original"}
                onClick={() => this.setState({ tab: "original" })}
              >
                <Tab>Original</Tab>
                <Hint hint="This is a copy of your original description before any Descriptify changes. At any time, you can click 'Revert To Original' to go back to this description in your store." />
              </TabRow>

              <TabRow
                active={this.state.tab === "current"}
                onClick={this.showEditableDescription}
              >
                <Tab>Edit</Tab>
                <Hint hint="Edit the active description in your Shopify store. Please note this will be overwritten if you generate a new description." />
              </TabRow>
            </MainTabsRow>

            <TabSpacer>
              {this.state.tab === "preview" && (
                <React.Fragment>
                  {!fetching && (
                    <Button
                      type="main-hollow"
                      text="Refresh Preview"
                      buttonStyle="margin-left: 20px; width: 180px;"
                      onClick={e =>
                        fetchDescription(description.product_id, fixed, e)
                      }
                    />
                  )}
                  {!fetching && (
                    <Button
                      isFetching={this.props.isUpdatingDescription}
                      type="main"
                      text="Send To Shopify"
                      disabled={this.props.isUpdatingDescription}
                      buttonStyle="margin-left: 20px; width: 180px;"
                      onClick={e =>
                        updateDescription(description.product_id, e)
                      }
                    />
                  )}
                </React.Fragment>
              )}
              {this.state.tab === "original" && !fetching && (
                <Button
                  isFetching={this.props.isUpdatingDescription}
                  type="main"
                  text="Revert To Original"
                  disabled={this.props.isUpdatingDescription}
                  buttonStyle="margin-left: 20px; width: 180px;"
                  onClick={e => updateDescription(description.product_id, e)}
                />
              )}
              {this.state.tab === "current" && !fetching && (
                <Button
                  isFetching={this.props.isUpdatingDescription}
                  type="main"
                  text="Update In Shopify"
                  disabled={this.props.isUpdatingDescription}
                  buttonStyle="margin-left: 20px; width: 180px;"
                  onClick={() =>
                    this.props.editDescription(
                      description.product_id,
                      this.state.editableDescription
                    )
                  }
                />
              )}
            </TabSpacer>
          </TabsContainer>

          {["original", "preview"].includes(this.state.tab) ? (
            <PreviewInner>
              {fetching ? (
                <MDSpinner singleColor={theme.colors.main} size="40px" />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: visibleDescription }} />
              )}
            </PreviewInner>
          ) : (
            <div style={{ padding: "30px" }}>
              <EditText>Looking to edit the description manually?</EditText>
              <EditTextSmall>
                Please note that changes made in Shopify will not be tracked,
                and will be overwritten if you generate a new description in
                Descriptify.
              </EditTextSmall>
              <Button
                text="Edit In Shopify"
                onClick={this.editInShopify}
                buttonStyle="width: 180px; font-size: 20px; padding: 15px;"
              />
              {/* <EditText>
                Edit the active description in your Shopify store. Please note
                this will be overwritten if you generate a new description in
                Descriptify.
              </EditText> */}
              {/* <FroalaEditor
                model={this.state.editableDescription}
                onModelChange={description =>
                  this.setState({ editableDescription: description })
                }
              /> */}
            </div>
          )}
        </Whatever>
      </Wrapper>
    );
  }
}

export default Description;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  flex-direction: column;
  padding-bottom: 80px;
`;

export const Whatever = styled.div`
  height: 100%;
  padding-bottom: 100px;
`;

export const PreviewInner = styled.div`
  background: white;
  padding: 30px;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  max-width: 900px;
`;

export const EditText = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 20px;
`;

export const EditTextSmall = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 30px;
`;

export const Titlebar = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ExpandRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: ${p => (p.visible ? "0px" : "1px")} solid
    ${theme.colors.lightBorder};
  padding: 20px;
`;

export const BackRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 20px;
  justify-content: space-between;
`;

export const BackTextRow = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  &:hover {
    opacity: 0.8;
  }
`;

export const BackArrow = styled.img`
  height: 16px;
  opacity: 0.8;
  margin-right: 5px;
  transform: rotate(90deg);
`;

export const Arrow = styled.img`
  height: 26px;
  opacity: 0.8;
  margin-left: 20px;
  transition: 300ms ease all;
  transform: ${p => (p.visible ? `rotate(180deg)` : `rotate(0deg)`)};
`;

export const CheckIcon = styled.img`
  height: 15px;
  margin-left: 10px;
`;

export const FixedText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.main};
  width: 220px;
  text-align: right;
`;

export const TabsContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const MainTabsRow = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  font-size: 18px;
  text-align: center;
`;

export const TabSpacer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
`;

export const TabRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: ${p => (p.active ? theme.colors.main : theme.colors.medDarkGrey)};
  width: 200px;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid
    ${p => (p.active ? "white" : theme.colors.lightBorder)};
  border-right: 1px solid ${theme.colors.lightBorder};
`;
