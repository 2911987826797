import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Option = styled.div`
  font-size: 13px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  cursor: pointer;
  color: ${theme.colors.darkGrey};

  ${p =>
    p.selected &&
    `
      border: 1px solid ${theme.colors.main};
      background: ${theme.colors.main};
      color: white;
  `}

  &:hover {
    border: 1px solid ${theme.colors.mainDark};
    ${p => p.selected && `background: ${theme.colors.mainDark};`}
  }
  transition: 0.3s;
`;

class SettingToggle extends Component {
  render() {
    return (
      <OptionsWrapper>
        {this.props.options.map(option => (
          <Option
            key={option.value}
            onClick={() => this.props.select(option.value)}
            selected={option.value === this.props.selected}
          >
            {option.label}
          </Option>
        ))}
      </OptionsWrapper>
    );
  }
}

export default SettingToggle;
