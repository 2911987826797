import Cookies from "js-cookie";
import { api } from "modules/Config";
import { history } from "App";

const type = "base";
export const LOGIN_REQ = `${type}/LOGIN_REQ`;
export const LOGIN_RES = `${type}/LOGIN_RES`;
export const LOGIN_ERR = `${type}/LOGIN_ERR`;
export const FETCH_PRODUCTS_REQ = `${type}/FETCH_PRODUCTS_REQ`;
export const FETCH_PRODUCTS_RES = `${type}/FETCH_PRODUCTS_RES`;
export const FETCH_PRODUCTS_ERR = `${type}/FETCH_PRODUCTS_ERR`;
export const UPDATE_SETTINGS_REQ = `${type}/UPDATE_SETTINGS_REQ`;
export const UPDATE_SETTINGS_RES = `${type}/UPDATE_SETTINGS_RES`;
export const UPDATE_SETTINGS_ERR = `${type}/UPDATE_SETTINGS_ERR`;
export const FETCH_SETTINGS_REQ = `${type}/FETCH_SETTINGS_REQ`;
export const FETCH_SETTINGS_RES = `${type}/FETCH_SETTINGS_RES`;
export const FETCH_SETTINGS_ERR = `${type}/FETCH_SETTINGS_ERR`;
export const UPDATE_DESCRIPTION_REQ = `${type}/UPDATE_DESCRIPTION_REQ`;
export const UPDATE_DESCRIPTION_RES = `${type}/UPDATE_DESCRIPTION_RES`;
export const UPDATE_DESCRIPTION_ERR = `${type}/UPDATE_DESCRIPTION_ERR`;
export const UPDATE_MULTIPLE_REQ = `${type}/UPDATE_MULTIPLE_REQ`;
export const UPDATE_MULTIPLE_RES = `${type}/UPDATE_MULTIPLE_RES`;
export const CREATE_BADGE_REQ = `${type}/CREATE_BADGE_REQ`;
export const CREATE_BADGE_RES = `${type}/CREATE_BADGE_RES`;
export const CREATE_BADGE_ERR = `${type}/CREATE_BADGE_ERR`;
export const DELETE_BADGE_REQ = `${type}/ DELETE_BADGE_REQ`;
export const DELETE_BADGE_RES = `${type}/ DELETE_BADGE_RES`;
export const DELETE_BADGE_ERR = `${type}/ DELETE_BADGE_ERR`;
export const TOGGLE_PRODUCT = `${type}/TOGGLE_PRODUCT`;
export const TOGGLE_ALL_PRODUCTS = `${type}/TOGGLE_ALL_PRODUCTS`;
export const CLEAR_SELECTED_PRODUCTS = `${type}/CLEAR_SELECTED_PRODUCTS`;
export const UPDATE_SETTING = `${type}/UPDATE_SETTING`;
export const SELECT_BADGE = `${type}/SELECT_BADGE`;
export const UPDATE_BADGE_LABEL = `${type}/UPDATE_BADGE_LABEL`;
export const PLAN_SAVED_REQ = `${type}/PLAN_SAVED_REQ`;
export const PLAN_SAVED_RES = `${type}/PLAN_SAVED_RES`;
export const PLAN_SAVED_ERR = `${type}/PLAN_SAVED_ERR`;
export const SET_PLAN_REQ = `${type}/SET_PLAN_REQ`;
export const SET_PLAN_RES = `${type}/SET_PLAN_RES`;
export const SET_PLAN_ERR = `${type}/SET_PLAN_ERR`;
export const UPDATE_COLLECTION = `${type}/UPDATE_COLLECTION`;

export const checkStatus = response => {
  if (response.status === 401) {
    Cookies.remove("accessToken");
    history.push("/");
  }
  return response.json();
};

export const toggleAll = bool => dispatch => {
  dispatch({
    type: TOGGLE_ALL_PRODUCTS,
    payload: { bool }
  });
};

export const toggleProduct = productId => dispatch => {
  dispatch({
    type: TOGGLE_PRODUCT,
    payload: { productId }
  });
};

export const updateCollection = collectionId => dispatch => {
  dispatch({
    type: UPDATE_COLLECTION,
    payload: { collectionId }
  });
};

export const clearSelectedProducts = () => dispatch => {
  dispatch({
    type: CLEAR_SELECTED_PRODUCTS
  });
};

export const sendSupportEmail = data => dispatch => {
  fetch(`${api}/support_email/`, {
    method: "POST",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const deleteBadge = badgeId => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: DELETE_BADGE_REQ,
      payload: { badgeId }
    });
    fetch(`${api}/badges/`, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ badge_id: badgeId })
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);
        dispatch({
          type: DELETE_BADGE_RES,
          payload: { badgeId }
        });
      })
      .catch(error => {
        dispatch({
          type: DELETE_BADGE_ERR,
          payload: { badgeId, error: "Error deleting badge." }
        });
      });
  });

export const createBadge = (icon, label) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: CREATE_BADGE_REQ
    });
    fetch(`${api}/badges/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ icon, label })
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);
        dispatch({
          type: CREATE_BADGE_RES,
          payload: { badges: response.badges }
        });
      })
      .catch(error => {
        dispatch({
          type: CREATE_BADGE_ERR,
          payload: { error: error.error }
        });
      });
  });

export const login = data => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: LOGIN_REQ
    });
    fetch(`${api}/store_auth/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => checkStatus(response))
      .then(response => {
        if (response.token) {
          Cookies.set("accessToken", response.token, { expires: 10 });

          resolve(response);

          dispatch({
            type: LOGIN_RES
          });
        }

        if (response.error) {
          dispatch({
            type: LOGIN_ERR,
            payload: { error: response.error }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: LOGIN_ERR,
          payload: { error: error.error }
        });
      });
  });

export const fetchProducts = (page, collection, search, vendor) => dispatch => {
  dispatch({
    type: UPDATE_COLLECTION,
    payload: { collectionId: collection }
  });

  dispatch({
    type: FETCH_PRODUCTS_REQ
  });
  fetch(
    `${api}/products/?page=${page}&collection=${collection}&search=${search}&vendor=${vendor}`,
    {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      }
    }
  )
    .then(response => checkStatus(response))
    .then(response => {
      dispatch({
        type: FETCH_PRODUCTS_RES,
        payload: {
          products: response.products,
          next: response.next,
          page: response.page
        }
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_PRODUCTS_ERR,
        payload: { error: error.error }
      });
    });
};

export const editDescription = (productId, editedContent) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: UPDATE_DESCRIPTION_REQ,
      payload: { productId }
    });
    fetch(`${api}/edit_description/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        shopify_id: productId,
        edited_html: editedContent
      })
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);
        dispatch({
          type: UPDATE_DESCRIPTION_RES,
          payload: { productId, productCount: response.product_count }
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_DESCRIPTION_ERR,
          payload: { error: error.error, productId }
        });
      });
  });

export const resetDescription = productId => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: UPDATE_DESCRIPTION_REQ,
      payload: { productId }
    });
    fetch(`${api}/reset/?shopify_id=${productId}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);
        dispatch({
          type: UPDATE_DESCRIPTION_RES,
          payload: { productId, fixed: false }
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_DESCRIPTION_ERR,
          payload: { error: error.error, productId }
        });
      });
  });

export const updateDescription = (productId, multipleIds = null) => dispatch =>
  new Promise(resolve => {
    if (multipleIds) {
      dispatch({
        type: UPDATE_MULTIPLE_REQ,
        payload: { multipleIds }
      });
    } else {
      dispatch({
        type: UPDATE_DESCRIPTION_REQ,
        payload: { productId }
      });
    }

    fetch(`${api}/description/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ shopify_id: productId, multiple_ids: multipleIds })
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);

        if (multipleIds) {
          dispatch({
            type: UPDATE_MULTIPLE_RES,
            payload: {
              multipleIds,
              success: response.success,
              productCount: response.product_count
            }
          });
        } else {
          dispatch({
            type: UPDATE_DESCRIPTION_RES,
            payload: {
              productId,
              fixed: true,
              success: response.success,
              productCount: response.product_count
            }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_DESCRIPTION_ERR,
          payload: { error: error.error, productId, multipleIds }
        });
      });
  });

export const fetchDescription = (productId, category = "") => dispatch =>
  new Promise(resolve => {
    fetch(`${api}/description/?product_id=${productId}&category=${category}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);
      });
  });

export const fetchSettings = () => dispatch => {
  dispatch({
    type: FETCH_SETTINGS_REQ
  });
  fetch(`${api}/settings/`, {
    method: "GET",
    headers: {
      Authorization: `Token ${Cookies.get("accessToken")}`,
      "Content-Type": "application/json"
    }
  })
    .then(response => checkStatus(response))
    .then(response => {
      const store = response.store;

      if (!store.active) {
        Cookies.remove("accessToken");
        history.push("/");
      }

      if (!store.is_paid) {
        history.push("/upgrade");
      }

      window.Intercom("boot", {
        app_id: "ulrlc7kx",
        app: "Descriptify",
        email: store.email,
        name: store.owner_name,
        descriptify_created_at: store.created_date,
        descriptify_days_left_in_trial: store.trial_days,
        descriptify_product_count: store.product_count,
        store_url: store.url,
        store_id: store.id
      });

      dispatch({
        type: FETCH_SETTINGS_RES,
        payload: {
          settings: response.settings,
          store: response.store,
          collections: response.collections
        }
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_SETTINGS_ERR,
        payload: { error: error.error }
      });
    });
};

export const updateBadgeLabel = (badgeId, label) => dispatch => {
  dispatch({
    type: UPDATE_BADGE_LABEL,
    payload: { badgeId, label }
  });
};

export const selectBadge = badgeId => dispatch => {
  dispatch({
    type: SELECT_BADGE,
    payload: { badgeId }
  });
};

export const updateSetting = (key, value) => dispatch => {
  dispatch({
    type: UPDATE_SETTING,
    payload: { key, value }
  });
};

export const saveSettings = settings => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: UPDATE_SETTINGS_REQ
    });
    fetch(`${api}/settings/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(settings)
    })
      .then(response => checkStatus(response))
      .then(() => {
        resolve();
        dispatch({
          type: UPDATE_SETTINGS_RES
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_SETTINGS_ERR,
          payload: { error: error.error }
        });
      });
  });

export const setPlanApproval = (chargeId, type) => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: PLAN_SAVED_REQ
    });
    fetch(`${api}/upgrade/?charge=${chargeId}&type=${type}`, {
      method: "GET",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      }
    })
      .then(response => checkStatus(response))
      .then(response => {
        resolve(response);
      })
      .catch(() => {
        dispatch({
          type: PLAN_SAVED_ERR,
          payload: { error: "Error choosing plan" }
        });
      });
  });

export const setPlan = type => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_PLAN_REQ
    });
    fetch(`${api}/upgrade/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${Cookies.get("accessToken")}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ type })
    })
      .then(response => checkStatus(response))
      .then(response => {
        if (response.success) {
          resolve(response);
        }
      })
      .catch(() => {
        dispatch({
          type: SET_PLAN_ERR,
          payload: { error: "Error choosing plan" }
        });
      });
  });
