import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { setPlan } from "Actions";
import { getSettings, getStore, getIsFetchingSettings } from "Selectors";
import { get } from "lodash";
import {
  BannerColor,
  Banner,
  Headline,
  Subtitle
} from "views/guest/LandingBanner";
import beforeAfter from "images/before-after-example.png";
import badgeCreation from "images/badge-creation.png";
import {
  ContentWhite,
  Content,
  ContentColumnRight,
  ContentColumnLeft,
  ContentHeader,
  ContentDescription,
  CTAButton,
  BeforeAfter,
  ContentGrey,
  BadgeCreation
} from "views/guest/Landing";
import MDSpinner from "react-md-spinner";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 30px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
`;

export const LoadingText = styled.div`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
  color: ${theme.colors.medDarkGrey};
`;

const mapStateToProps = state => ({
  isFetchingSettings: getIsFetchingSettings(state),
  settings: getSettings(state),
  store: getStore(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      setPlan
    },
    dispatch
  )
});

class Upgrade extends Component {
  state = { fetching: false };
  upgrade = type => {
    this.setState({ fetching: true }, () => {
      this.props.setPlan(type).then(response => {
        if (response.plan_auth_url) {
          window.location = response.plan_auth_url;
        }
      });
    });
  };

  render() {
    // const productCount = get(this.props, ["store", "product_count"]);
    // const trialDays = get(this.props, ["store", "trial_days"]);
    // const paidInTrial = get(this.props, ["store", "paid_in_trial"]);
    // const pastLimit = !paidInTrial && trialDays < 1 && productCount > 15;
    const pastLimit = false;

    if (this.props.isFetchingSettings) {
      return (
        <LoadingWrapper>
          <LoadingText>Loading your account....</LoadingText>
          <MDSpinner singleColor="#363636" size="80px" />
        </LoadingWrapper>
      );
    }

    return (
      <Wrapper>
        <BannerColor>
          {pastLimit ? (
            <Banner>
              <Headline>Trial Limit Reached</Headline>
              <Subtitle style={{ marginBottom: "30px" }}>
                Your free trial is limited to 15 product description fixes.
                Upgrade now to the full version to get unlimited access!
              </Subtitle>
              <CTAButton onClick={() => this.upgrade("paid_in_trial")}>
                {this.state.fetching ? (
                  <MDSpinner singleColor="#363636" size="20px" />
                ) : (
                  "UPGRADE NOW"
                )}
              </CTAButton>
              <Subtitle style={{ fontSize: "18px" }}>
                * Billed at $9.99 per month
              </Subtitle>
            </Banner>
          ) : (
            <Banner>
              <Headline>Welcome to Descriptify!</Headline>
              <Subtitle style={{ marginBottom: "30px" }}>
                Now that you've found us, you'll never spend hours editing bad
                product descriptions again!
              </Subtitle>
              <CTAButton onClick={() => this.upgrade("trial")}>
                {this.state.fetching ? (
                  <MDSpinner singleColor="#363636" size="20px" />
                ) : (
                  "START 7 DAY FREE TRIAL"
                )}
              </CTAButton>
              <Subtitle style={{ fontSize: "18px" }}>
                * No commitment, cancel anytime
              </Subtitle>
            </Banner>
          )}
        </BannerColor>

        <ContentWhite>
          <Content>
            <ContentColumnRight>
              <ContentHeader>
                Professional Descriptions In Seconds
              </ContentHeader>
              <ContentDescription>
                Instantly overhaul your product descriptions with a beautifully
                designed layout by our graphic design team.
              </ContentDescription>
              <CTAButton
                greenHollow
                style={{ marginTop: "30px" }}
                onClick={() =>
                  this.upgrade(pastLimit ? "paid_in_trial" : "trial")
                }
              >
                {this.state.fetching ? (
                  <MDSpinner singleColor="#49beaf" size="20px" />
                ) : pastLimit ? (
                  "UPGRADE NOW"
                ) : (
                  "START 7 DAY FREE TRIAL"
                )}
              </CTAButton>
            </ContentColumnRight>
            <BeforeAfter src={beforeAfter} />
          </Content>
        </ContentWhite>
        <ContentGrey>
          <Content>
            <BadgeCreation src={badgeCreation} />
            <ContentColumnLeft>
              <ContentHeader>Create Custom Badges</ContentHeader>
              <ContentDescription>
                Choose from dozens of high quality icons and customize the color
                and text to add a special flair to your product descriptions!
              </ContentDescription>

              <CTAButton
                green
                style={{ marginTop: "30px" }}
                onClick={() => this.upgrade("trial")}
              >
                {this.state.fetching ? (
                  <MDSpinner singleColor="#ffffff" size="20px" />
                ) : pastLimit ? (
                  "UPGRADE NOW"
                ) : (
                  "START 7 DAY FREE TRIAL"
                )}
              </CTAButton>
            </ContentColumnLeft>
          </Content>
        </ContentGrey>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Upgrade)
);
