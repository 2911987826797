import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => (p.justifyCenter ? "center" : "flex-start")};
  align-content: ${p => (p.alignCenter ? "center" : "flex-start")};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${p => (p.justifyCenter ? "center" : "flex-start")};
  align-content: ${p => (p.alignCenter ? "center" : "flex-start")};
`;
