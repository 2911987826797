export const theme = {
  colors: {
    lightGrey: "#e0e8e7",
    medGrey: "#93aaa8",
    darkGrey: "#233534",
    medDarkGrey: "#485d5a",
    main: "#49beaf",
    mainDark: "#39a698",
    mainLight: "#8dc4bd",
    white: "white",
    lightBorder: "#d6dedd",
    orange: "#e48832",
    red: "#c41a1a"
  }
};
