import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import Hint from "components/Hint";
import { Row } from "components/Layout";

export const StyledLabel = styled.div`
  font-size: 16px;
  font-weight: ${p => (p.bold ? "700" : "400")};
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 8px;
  ${props => props.extraStyle};
`;

class Label extends Component {
  state = { showHint: false };
  render() {
    return (
      <Row style={{ position: "relative" }}>
        <StyledLabel bold={this.props.bold}>{this.props.label}</StyledLabel>
        {this.props.hint && (
          <Hint hint={this.props.hint} narrow left={this.props.hintLeft} />
        )}
      </Row>
    );
  }
}

export default Label;
