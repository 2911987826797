import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Column } from "components/Layout";
import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
  margin-top: 200px;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
    margin-top: 50px;
  }
`;

export const FooterLink = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  @media (max-width: 800px) {
    margin: 7px;
  }
`;

export const FooterCopyright = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.7);
`;

export const FooterTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin: 10px;
  cursor: pointer;
  color: white;
  @media (max-width: 800px) {
    margin-top: 30px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 600px;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ContentGreen = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px;
  background: linear-gradient(to top, #49c0b0 0%, #42b5b4 100%);
  clip-path: polygon(0% 30%, 100% 0%, 100% 100%, 0 100%);
  padding-bottom: 100px;
  padding-top: 0px;
  @media (max-width: 800px) {
    clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0 100%);
    padding-bottom: 50px;
  }
`;

class LandingFooter extends Component {
  goTo = (link, internal = true) => {
    if (internal) {
      this.props.history.push(link);
    } else {
      window.open(link);
    }
  };

  render() {
    return (
      <ContentGreen>
        <FooterContainer>
          <FooterLinksWrapper>
            <Column>
              <FooterTitle>FOR STORES</FooterTitle>
              <FooterLink
                onClick={() =>
                  this.goTo("https://apps.shopify.com/descriptify", false)
                }
              >
                SIGN UP / LOGIN
              </FooterLink>
              <FooterLink
                onClick={() =>
                  this.goTo(
                    "https://help.dropcommerce.co/en/collections/1958572-descriptify",
                    false
                  )
                }
              >
                FAQ
              </FooterLink>
              {/* <FooterLink onClick={() => this.goTo("/pricing")}>
                PRICING
              </FooterLink> */}
            </Column>
            <Column>
              <FooterTitle>GENERAL</FooterTitle>
              <FooterLink
                onClick={() =>
                  this.goTo(
                    "https://help.dropcommerce.co/en/collections/1958572-descriptify",
                    false
                  )
                }
              >
                HELP CENTER
              </FooterLink>
              <FooterLink onClick={() => this.goTo("/terms")}>TERMS</FooterLink>
              <FooterLink onClick={() => this.goTo("/privacy")}>
                PRIVACY
              </FooterLink>
              <FooterLink onClick={() => this.goTo("/contact")}>
                CONTACT US
              </FooterLink>
            </Column>
          </FooterLinksWrapper>
          <FooterCopyright>Descriptify © 2019</FooterCopyright>
        </FooterContainer>
      </ContentGreen>
    );
  }
}

export default withRouter(LandingFooter);
