import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import SkeletonLoader from "components/SkeletonLoader";
import check from "images/check-circle-green.svg";
import Button from "components/Button";
import { truncateText } from "modules/Format";
import { Row, Column } from "components/Layout";
import checkIcon from "images/check-green.svg";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 5px;
`;

export const SelectedWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background: rgb(73, 190, 175, 0.05);
    ${p => p.selected && "background: rgb(73, 190, 175, 0.15);"}
  }
  ${p => p.selected && "background: rgb(73, 190, 175, 0.25);"}
`;

export const ImageSkeleton = styled(SkeletonLoader)`
  height: 50px;
  width: 50px;
  max-width: 50px;
  border-radius: 4px;
  margin-right: 30px;
`;

export const Image = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 4px;
  margin-right: 30px;
  @media (max-width: 800px) {
    height: 35px;
    width: 35px;
    margin-right: 15px;
  }
`;

export const Check = styled.img`
  height: 35px;
  width: 35px;
  margin-right: 30px;
  opacity: ${p => (p.selected ? "1.0" : "0.15")};
  @media (max-width: 800px) {
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  opacity: ${p => (p.fixed ? "0.3" : "1.0")};
  width: 300px;
`;

export const FixedText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.main};
`;

export const ErrorText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.colors.red};
`;

export const TitleSkeleton = styled(SkeletonLoader)`
  height: 25px;
  width: 300px;
  max-width: 300px;
  margin-right: 30px;
`;

export const CheckIcon = styled.img`
  height: 15px;
  margin-left: 10px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  margin-right: ${p => (p.marginRight ? "15px" : "0px")};
  width: ${p => p.width};
  @media (max-width: 800px) {
    padding: 8px;
    font-size: 12px;
    display: inline-block;
    min-width: auto;
    width: auto;
  }
`;

class Product extends Component {
  fixProductTitle = (title, fixTitle, limit) => {
    if (fixTitle) {
      let count = 0;
      let newTitle = "";

      title.split(" ").forEach(word => {
        count += 1;
        if (count <= limit && !["WITH", "AND"].includes(word.toUpperCase())) {
          if (count > 1) {
            newTitle += " ";
          }

          newTitle += word;
        }
      });

      return newTitle;
    }

    return truncateText(title, 40);
  };

  render() {
    const {
      skeleton,
      product,
      fetchDescription,
      updateDescription,
      resetDescription,
      fixTitle,
      titleLimit
    } = this.props;

    if (skeleton) {
      return (
        <Wrapper>
          <SelectedWrapper>
            <Check src={check} />
            <ImageSkeleton width="50px" />
            <TitleSkeleton width="300px" />
            <StyledButton
              text="Preview"
              width="100px"
              marginRight
              type="main-hollow"
            />
            <StyledButton text="Send To Shopify" width="150px" />
          </SelectedWrapper>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <SelectedWrapper
            selected={product.selected}
            onClick={this.props.toggle}
          >
            <ProductRow>
              <Row style={{ alignItems: "center" }}>
                <Check selected={product.selected} src={check} />
                <Image src={product.image.src} />

                <Column style={{ marginRight: "30px" }}>
                  <Title fixed={product.fixed}>
                    {this.fixProductTitle(product.title, fixTitle, titleLimit)}
                  </Title>
                  {product.fixed && (
                    <Row alignCenter style={{ marginTop: "10px" }}>
                      <FixedText>Product Updated In Shopify</FixedText>
                      <CheckIcon src={checkIcon} />
                    </Row>
                  )}
                  {product.error && (
                    <Row alignCenter style={{ marginTop: "10px" }}>
                      <ErrorText>Error updating description</ErrorText>
                    </Row>
                  )}
                </Column>
              </Row>

              <ButtonsRow>
                <StyledButton
                  text="Preview"
                  onClick={fetchDescription}
                  width="100px"
                  marginRight
                  type="main-hollow"
                />
                <StyledButton
                  type="main"
                  text="Send To Shopify"
                  width="150px"
                  marginRight
                  onClick={e => updateDescription(product.id, e)}
                  isFetching={product.isFetching}
                />
                {product.fixed && (
                  <StyledButton
                    text="Reset"
                    onClick={e => resetDescription(product.id, e)}
                    width="100px"
                    isFetching={product.isFetching}
                    type="main-hollow"
                  />
                )}
              </ButtonsRow>
            </ProductRow>
          </SelectedWrapper>
        </Wrapper>
      );
    }
  }
}

export default Product;
