import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route } from "react-router-dom";
import { web } from "modules/Config";
import { getParameterByName, randomString } from "modules/Format";
import Cookies from "js-cookie";
import "babel-polyfill";
import "isomorphic-fetch";

serviceWorker.unregister();

const code = getParameterByName("code");
const hmac = getParameterByName("hmac");
const shop = getParameterByName("shop");
const state = getParameterByName("state");
const timestamp = getParameterByName("timestamp");

if (shop && hmac && timestamp && !code && !state) {
  const randString = randomString(20);
  Cookies.set("nonce", randString, { expires: 1 });
  const redirectUri = `${web}/login/`;

  // TODO - FIX THIS
  var appId = "57cdc635ee29dcdf8769a579e5b11dfe";
  // var appId = process.env.REACT_APP_SHOPIFY_API_KEY;
  console.log("app id", appId);
  var scopes =
    "read_products,write_products,read_inventory,write_inventory,read_orders,write_orders";
  var permissionUrl = `/oauth/authorize?client_id=${appId}&amp;scope=${scopes}&amp;redirect_uri=${redirectUri}&amp;state=${randString}&amp;grant_options[]=`;
  var url = `https://${shop}/admin${permissionUrl}`;
  window.top.location.href = url;
} else {
  ReactDOM.render(
    <BrowserRouter>
      <Route component={App} />
    </BrowserRouter>,
    document.getElementById("root")
  );
}
