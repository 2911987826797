import React, { Component } from "react";
import styled from "styled-components";
import MDSpinner from "react-md-spinner";
import { theme } from "modules/Theme";

export const StyledButton = styled.div`

  font-size: 14px;
  color: ${theme.colors.white};
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  width: ${props => (props.fixedWidth ? "170px" : "auto")};
  min-width: 100px;
  ${props =>
    props.fit &&
    `
      width: auto;
      white-space: nowrap;
    `};
  transition: 0.3s;

  ${props =>
    props.type === "red" &&
    `
    background: ${theme.colors.accent};
    color: ${theme.colors.white};
    padding: 5px;
    &:hover {
      background: ${theme.colors.darkRed};
    }
  `}

  ${props =>
    props.type === "main-hollow" &&
    `
    border: 1px solid ${theme.colors.main};
    color: ${theme.colors.main};
    &:hover {
      background: ${theme.colors.main};
      color: ${theme.colors.white};
    }
  `}

  ${props =>
    props.type === "white-hollow" &&
    `
    border: 1px solid white;
    color: ${theme.colors.white};
    &:hover {
      background: ${theme.colors.mainDark};
    }
  `}

    ${props =>
      props.type === "main" &&
      `
    background: ${theme.colors.main};
    color: ${theme.colors.white};
    &:hover {
      background: ${theme.colors.mainDark};
    }
  `}

    ${props =>
      props.type === "main-light" &&
      `
    background: ${theme.colors.main};
    color: ${theme.colors.white};
    opacity: 0.5;
    &:hover {
      opacity: 0.7;
    }
  `}

  ${props => props.buttonStyle};
  @media screen and (max-width: 800px) {
    ${props => props.mobile100 && "width: 100%;"}
  }
`;

export const ButtonIcon = styled.img`
  height: 18px;
  padding: 0px;
  margin-right: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

class Button extends Component {
  state = { hover: false };
  render() {
    const {
      buttonStyle,
      type,
      text,
      disabled,
      onClick,
      isFetching,
      className
    } = this.props;
    return (
      <StyledButton
        className={className}
        buttonStyle={buttonStyle}
        type={type || "main"}
        onClick={disabled ? null : onClick}
        disabled={disabled}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {isFetching ? (
          <MDSpinner
            singleColor={
              type === "main-hollow" && !this.state.hover
                ? "rgb(73,190,175)"
                : "rgb(255, 255, 255)"
            }
            size="20px"
          />
        ) : (
          <ButtonWrapper>{text}</ButtonWrapper>
        )}
      </StyledButton>
    );
  }
}

export default Button;
