import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import Button from "components/Button";
import { Row } from "components/Layout";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50px;
  right: 50px;
  background: white;
  padding: 30px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  z-index: 9999;
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div`
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 600;
  color: ${theme.colors.medDarkGrey};
`;

export const Subtitle = styled.div`
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 300;
  color: white;
`;

class Popup extends Component {
  render() {
    return (
      <Container>
        <Title>{this.props.title}</Title>
        {this.props.subtitle && <Subtitle>{this.props.subtitle}</Subtitle>}

        <Row>
          {this.props.action && (
            <Button
              text={this.props.actionText}
              onClick={this.props.action}
              buttonStyle="margin-right: 15px;"
            />
          )}
          <Button text="Hide Notice" onClick={this.props.hide} />
        </Row>
      </Container>
    );
  }
}

export default Popup;
