import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { ReactComponent as Logo } from "images/descriptify-logo.svg";
import { getUrlParameter } from "modules/Format";
import Cookies from "js-cookie";
import MDSpinner from "react-md-spinner";
import { get } from "lodash";
import { login } from "Actions";
import { getIsFetching } from "Selectors";

const mapStateToProps = state => ({
  isFetching: getIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({ login }, dispatch)
});

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.main};
  position: fixed;
  top: 0;
  bottom: 0;
`;

export const StyledLogo = styled(Logo)`
  height: 200px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    height: 100px;
  }
`;

export const Error = styled.div`
  font-size: 30px;
  font-weight: 300;
  color: white;
`;

class Login extends Component {
  state = { nonceError: false };

  validateShopifyUrlParams = () => {
    const loc = this.props.location;
    const nonce = Cookies.get("nonce");

    const data = {
      code: getUrlParameter("code", loc),
      shop: getUrlParameter("shop", loc),
      state: getUrlParameter("state", loc),
      timestamp: getUrlParameter("timestamp", loc),
      hmac: getUrlParameter("hmac", loc)
    };

    if (data.code && data.hmac && data.shop && data.state && data.timestamp) {
      if (nonce === data.state) {
        this.props.login(data).then(response => {
          if (response.token) {
            this.props.history.push("/dashboard");
          }
        });
      } else {
        this.setState({ nonceError: true });
      }
    }
  };

  componentDidMount = () => {
    this.validateShopifyUrlParams();
  };

  render() {
    const badHmac = get(this.props, ["errorType"]) === "bad_hmac";

    return (
      <Wrapper>
        <StyledLogo />
        {this.props.isFetching > 0 && !badHmac ? (
          <MDSpinner singleColor="white" size="60px" />
        ) : (
          <Error>
            There was an unexpected issue connecting with Shopify. Please try
            again.
          </Error>
        )}
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
