import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SettingToggle from "./SettingToggle";
import Label from "components/Label";
import Switch from "components/Switch";
import Badge from "../Badge";
import Input from "components/Input";

import { updateSetting } from "Actions";

import { getSettings, getIsFetchingSettings } from "Selectors";

const mapStateToProps = state => ({
  isFetchingSettings: getIsFetchingSettings(state),
  settings: getSettings(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSetting
    },
    dispatch
  )
});

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const BadgesRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const SettingOption = styled.div`
  margin-bottom: ${p => (p.isToggle ? "14px" : "22px")};
`;

class SettingsOptions extends Component {
  render() {
    const settings = get(this.props, "settings");
    const badgesLength = get(settings, ["badges", "length"]);

    if (this.props.isFetchingSettings > 0) {
      return (
        <React.Fragment>
          <Badge skeleton />
          <Badge skeleton />
          <Badge skeleton />
          <Badge skeleton />
          <Badge skeleton />
          <Badge skeleton />
        </React.Fragment>
      );
    }

    return settingsOptions.map(option => {
      // Check if conditional based on parent
      if (!option.parent || (option.parent && get(settings, option.parent))) {
        // Check if conditional requirement
        if (
          !option.requirement ||
          (option.requirement === "badges_exist" && badgesLength > 0)
        ) {
          if (option.type === "input") {
            return (
              <SettingOption key={option.label}>
                <Label
                  bold
                  marginBottom="0px"
                  label={option.label}
                  hint={option.hint}
                  hintLeft={option.hintLeft}
                />
                <Input
                  type={option.input_type}
                  value={get(settings, option.key)}
                  onChange={e =>
                    this.props.updateSetting(option.key, e.target.value)
                  }
                />
              </SettingOption>
            );
          } else if (option.type === "true_false") {
            return (
              <SettingOption key={option.label}>
                <Label
                  bold
                  marginBottom="0px"
                  label={option.label}
                  hint={option.hint}
                  hintLeft={option.hintLeft}
                />

                <Switch
                  active={get(settings, option.key)}
                  label={option.label}
                  toggle={() =>
                    this.props.updateSetting(
                      option.key,
                      !get(settings, option.key)
                    )
                  }
                />
              </SettingOption>
            );
          } else if (option.type === "toggle") {
            return (
              <SettingOption isToggle key={option.label}>
                <Label
                  bold
                  marginBottom="0px"
                  label={option.label}
                  hint={option.hint}
                  hintLeft={option.hintLeft}
                />

                <SettingToggle
                  key={option.key}
                  selected={settings[option.key]}
                  select={value => this.props.updateSetting(option.key, value)}
                  options={option.options}
                />
              </SettingOption>
            );
          }
        }
      }
    });
  }
}

const settingsOptions = [
  // {
  //   key: "show_logo",
  //   label: "Show Logo",
  //   type: "true_false"
  // },

  {
    key: "display_name",
    label: "Store Name",
    type: "input",
    input_type: "text",
    hint: "The store name that will be used in the product description."
  },
  {
    key: "display_website",
    label: "Website",
    type: "input",
    input_type: "text",
    hint: "The website that will be used in the product description."
  },
  {
    key: "fix_product_title",
    label: "Shorten Product Title",
    type: "true_false",
    hintLeft: true,
    hint:
      "Fix long product titles by trimming them down to a maximum number of words."
  },
  {
    key: "product_title_limit",
    label: "Product Title Max Word Length",
    type: "input",
    input_type: "number",
    parent: "fix_product_title",
    hintLeft: true,
    hint:
      "The maximum number of words to use in a product title. Our system will trim your product titles to this amount of words."
  },
  {
    key: "include_tags",
    label: "Show Keyword Tags",
    type: "true_false",
    parent: "fix_product_title",
    hint:
      "If the product title is shortened, add the remaining words as 'tags' into the bottom of the product description."
  },
  {
    key: "show_banner",
    label: "Show Banner",
    type: "true_false",
    hint:
      "Show a colored banner with your store name, custom note and contact info."
  },
  {
    key: "tagline",
    label: "Banner Subtitle",
    type: "input",
    input_type: "text",
    parent: "show_banner",
    hint:
      "Customize the line of text under your store name on the description banner."
  },
  {
    key: "show_support",
    label: "Show Support Section",
    type: "true_false",
    hintLeft: true,
    hint:
      "Show a line of text at the bottom of the description with your support contact information."
  },
  {
    key: "support_email",
    label: "Support Email",
    type: "input",
    input_type: "text",
    parent: "show_support",
    hint: "The company email address that will be shown in the description."
  },
  {
    key: "include_original",
    label: "Include Original Description",
    type: "true_false",
    hintLeft: true,
    hint:
      "Include the original product description within the newly generated description."
  },
  {
    key: "show_variants",
    label: "Show Product Variants",
    type: "true_false",
    hintLeft: true,
    hint: "Show the product variants (Sizes, Colors, etc) in the description."
  },
  {
    key: "limit_variants",
    label: "Limit Variant #",
    type: "true_false",
    parent: "show_variants",
    hint:
      "Show max 10 variants and show 'And X more!' if there are more than 10. This helps when products have too many variants and clutters up the description."
  },
  {
    key: "variant_format",
    label: "Variant Format",
    type: "toggle",
    parent: "show_variants",
    options: [{ value: "LIST", label: "List" }, { value: "ROW", label: "Row" }],
    hint:
      "Whether the product variants display in a bulleted list or in a comma separated row."
  },
  {
    key: "title_font_size",
    label: "Title Font Size",
    type: "toggle",
    options: [
      { value: "SMALL", label: "Small" },
      { value: "MEDIUM", label: "Medium" },
      { value: "LARGE", label: "Large" }
    ],
    hint: "The font size of the product title in the description."
  },
  {
    key: "icon_color",
    label: "Text & Icon Color",
    type: "toggle",
    requirement: "badges_exist",
    options: [
      { value: "LIGHT", label: "Light" },
      { value: "DARK", label: "Dark" }
    ],
    hint: "Choose whether the text and icons are Light or Dark."
  },
  {
    key: "icon_size",
    label: "Badge Size",
    type: "toggle",
    requirement: "badges_exist",
    options: [
      { value: "SMALL", label: "Small" },
      { value: "LARGE", label: "Large" }
    ],
    hint: "Choose whether the badges are Small or Large."
  },
  {
    key: "icon_label_placement",
    label: "Badge Label Placement",
    type: "toggle",
    requirement: "badges_exist",
    options: [
      { value: "BELOW", label: "Below" },
      { value: "RIGHT", label: "Right" }
    ],
    hintLeft: true,
    hint:
      "Choose whether the badge labels show up below or to the right of the badges."
  },
  {
    key: "icon_label_size",
    label: "Badge Label Size",
    type: "toggle",
    requirement: "badges_exist",
    options: [
      { value: "SMALL", label: "Small" },
      { value: "LARGE", label: "Large" }
    ],
    hint: "The font size of the badge labels."
  },
  {
    key: "icon_row_spacing",
    label: "Badge Spacing",
    type: "toggle",
    requirement: "badges_exist",
    options: [
      { value: "SPACE_BETWEEN", label: "Space Between" },
      { value: "SPACE_AROUND", label: "Space Around" },
      { value: "ALIGN_LEFT", label: "Align Left" },
      { value: "ALIGN_RIGHT", label: "Align Right" }
    ],
    hint: "Choose how the badges in the description are spaced."
  }
];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsOptions);
