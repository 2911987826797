import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";

export const Container = styled.div`
  margin-top: ${p => (p.noTopMargin ? "0px" : "15px")};
  margin-left: ${p => (p.indent ? "20px" : "0px")};
  margin-bottom: 15px;
`;

export const SwitchWrapper = styled.div`
  background: ${p => (p.active ? theme.colors.main : "white")};
  width: 55px;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid
    ${p => (p.active ? theme.colors.main : theme.colors.medGrey)};
  padding: 2px;
  display: flex;
  justify-content: ${p => (p.active ? "flex-end" : "flex-start")};
  transition: 0.4s;
`;

export const SwitchBall = styled.div`
  background: ${p => (p.active ? "white" : theme.colors.medGrey)};
  border-radius: 100px;
  height: 20px;
  width: 20px;
  padding: 1px;
`;

class Switch extends Component {
  render() {
    return (
      <SwitchWrapper onClick={this.props.toggle} active={this.props.active}>
        <SwitchBall active={this.props.active} />
      </SwitchWrapper>

      // <Container
      //   indent={this.props.indent}
      //   noTopMargin={this.props.noTopMargin}
      // >
      //   <Column>
      //     {this.props.label && (
      //       <Label extraStyle="margin: 0px 15px 5px 0px;" bold>
      //         {this.props.label}
      //       </Label>
      //     )}

      //   </Column>

      // </Container>
    );
  }
}

export default Switch;
