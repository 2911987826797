import {
  LOGIN_REQ,
  LOGIN_RES,
  LOGIN_ERR,
  FETCH_PRODUCTS_REQ,
  FETCH_PRODUCTS_RES,
  FETCH_PRODUCTS_ERR,
  FETCH_SETTINGS_REQ,
  FETCH_SETTINGS_RES,
  FETCH_SETTINGS_ERR,
  TOGGLE_PRODUCT,
  TOGGLE_ALL_PRODUCTS,
  CLEAR_SELECTED_PRODUCTS,
  UPDATE_SETTING,
  UPDATE_SETTINGS_REQ,
  UPDATE_SETTINGS_RES,
  UPDATE_SETTINGS_ERR,
  UPDATE_DESCRIPTION_RES,
  UPDATE_DESCRIPTION_REQ,
  UPDATE_DESCRIPTION_ERR,
  UPDATE_MULTIPLE_RES,
  UPDATE_MULTIPLE_REQ,
  SELECT_BADGE,
  UPDATE_BADGE_LABEL,
  CREATE_BADGE_REQ,
  CREATE_BADGE_RES,
  CREATE_BADGE_ERR,
  DELETE_BADGE_REQ,
  DELETE_BADGE_RES,
  DELETE_BADGE_ERR,
  UPDATE_COLLECTION
} from "Actions";

const initialState = {
  isFetching: 0,
  isSavingSettings: 0,
  isFetchingSettings: 0,
  products: [],
  settings: {},
  pagination: {},
  collections: []
};

const base = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        settings: {
          ...state.settings,
          settingsChanged: true,
          [action.payload.key]: action.payload.value
        }
      };
    case DELETE_BADGE_REQ:
      return {
        ...state,
        settings: {
          ...state.settings,
          badges: state.settings.badges.map(badge => {
            if (badge.id === action.payload.badgeId) {
              return { ...badge, isDeleting: true };
            }
            return badge;
          })
        }
      };
    case DELETE_BADGE_RES:
      return {
        ...state,
        settings: {
          ...state.settings,
          badges: state.settings.badges.filter(
            badge => badge.id !== action.payload.badgeId
          )
        }
      };
    case DELETE_BADGE_ERR:
      return {
        ...state,
        error: action.payload.error,
        settings: {
          ...state.settings,
          badges: state.settings.badges.map(badge => {
            if (badge.id === action.payload.badgeId) {
              return { ...badge, isDeleting: false };
            }
            return badge;
          })
        }
      };
    case CREATE_BADGE_REQ:
      return {
        ...state,
        isCreatingBadge: true
      };
    case CREATE_BADGE_RES:
      return {
        ...state,
        isCreatingBadge: false,
        settings: {
          ...state.settings,
          badges: action.payload.badges
        }
      };
    case CREATE_BADGE_ERR:
      return {
        ...state,
        isCreatingBadge: false,
        error: action.payload.error
      };
    case SELECT_BADGE:
      return {
        ...state,
        settings: {
          ...state.settings,
          badgesChanged: true,
          badges: state.settings.badges.map(badge => {
            if (badge.id === action.payload.badgeId) {
              return { ...badge, active: !badge.active, changed: true };
            }
            return badge;
          })
        }
      };
    case UPDATE_BADGE_LABEL:
      return {
        ...state,
        settings: {
          ...state.settings,
          badgesChanged: true,
          badges: state.settings.badges.map(badge => {
            if (badge.id === action.payload.badgeId) {
              return { ...badge, label: action.payload.label, changed: true };
            }
            return badge;
          })
        }
      };
    case UPDATE_MULTIPLE_REQ:
      return {
        ...state,
        isFetchingMultiple: true,
        products: state.products.map(product => {
          if (action.payload.multipleIds.includes(product.id)) {
            return { ...product, isFetching: true };
          }
          return product;
        })
      };
    case UPDATE_DESCRIPTION_REQ:
      return {
        ...state,
        isUpdatingDescription: true,
        products: state.products.map(product => {
          if (product.id === action.payload.productId) {
            return { ...product, isFetching: true };
          }
          return product;
        })
      };
    case UPDATE_MULTIPLE_RES:
      return {
        ...state,
        isFetchingMultiple: false,
        store: { ...state.store, product_count: action.payload.productCount },
        products: state.products.map(product => {
          if (action.payload.multipleIds.includes(product.id)) {
            const success = action.payload.success.includes(product.id);
            return {
              ...product,
              isFetching: false,
              error: !success,
              fixed: success
            };
          }
          return product;
        })
      };
    case UPDATE_DESCRIPTION_RES:
      return {
        ...state,
        isUpdatingDescription: false,
        store: { ...state.store, product_count: action.payload.productCount },
        products: state.products.map(product => {
          if (product.id === action.payload.productId) {
            return {
              ...product,
              isFetching: false,
              fixed: action.payload.fixed
            };
          }
          return product;
        })
      };
    case UPDATE_DESCRIPTION_ERR:
      return {
        ...state,
        isUpdatingDescription: false,
        error: action.payload.error,
        products: state.products.map(product => {
          if (product.id === action.payload.productId) {
            return { ...product, isFetching: false };
          }
          return product;
        })
      };
    case UPDATE_COLLECTION:
      return {
        ...state,
        collections: state.collections.map(collection => {
          if (collection.value === action.payload.collectionId) {
            return { ...collection, selected: !collection.selected };
          }
          return { ...collection, selected: false };
        })
      };
    case TOGGLE_PRODUCT:
      return {
        ...state,
        products: state.products.map(product => {
          if (product.id === action.payload.productId) {
            return { ...product, selected: !product.selected };
          }
          return product;
        })
      };
    case TOGGLE_ALL_PRODUCTS:
      return {
        ...state,
        products: state.products.map(product => {
          return { ...product, selected: action.payload.bool };
        })
      };
    case CLEAR_SELECTED_PRODUCTS:
      return {
        ...state,
        products: state.products.map(product => {
          return { ...product, selected: false };
        })
      };
    case UPDATE_SETTINGS_REQ:
      return {
        ...state,
        isSavingSettings: state.isSavingSettings + 1
      };
    case FETCH_SETTINGS_REQ:
      return {
        ...state,
        isFetchingSettings: state.isFetchingSettings + 1
      };
    case FETCH_PRODUCTS_REQ:
    case LOGIN_REQ:
      return {
        ...state,
        isFetching: state.isFetching + 1
      };
    case UPDATE_SETTINGS_RES:
      return {
        ...state,
        isSavingSettings: state.isSavingSettings - 1,
        settings: {
          ...state.settings,
          settingsChanged: false,
          badgesChanged: false,
          badges: state.settings.badges.map(badge => {
            return { ...badge, changed: false };
          })
        }
      };
    case FETCH_SETTINGS_RES:
      return {
        ...state,
        isFetchingSettings: state.isFetchingSettings - 1,
        settings: action.payload.settings,
        store: action.payload.store,
        collections: action.payload.collections
      };
    case FETCH_PRODUCTS_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        products: action.payload.products,
        pagination: { next: action.payload.next, page: action.payload.page }
      };
    case LOGIN_RES:
      return {
        ...state,
        isFetching: state.isFetching - 1
      };
    case UPDATE_SETTINGS_ERR:
      return {
        ...state,
        isSavingSettings: state.isSavingSettings - 1,
        error: action.payload.error
      };
    case FETCH_SETTINGS_ERR:
      return {
        ...state,
        isFetchingSettings: state.isFetchingSettings - 1,
        error: action.payload.error
      };
    case FETCH_PRODUCTS_ERR:
    case LOGIN_ERR:
      return {
        ...state,
        isFetching: state.isFetching - 1,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default base;
