import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { ChromePicker } from "react-color";

import {
  updateSetting,
  saveSettings,
  selectBadge,
  updateBadgeLabel,
  createBadge,
  deleteBadge
} from "Actions";
import {
  getIsFetchingSettings,
  getIsSavingSettings,
  getSettings,
  getIsCreatingBadge
} from "Selectors";
import Badge from "../Badge";
import Button from "components/Button";
import Badges from "../Badges";
import SettingsOptions from "./SettingsOptions";
import { get } from "lodash";
import Text from "components/Text";

export const BadgesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
`;

export const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 10px;
`;

export const Link = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  margin-left: 20px;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-right: 1px solid ${theme.colors.lightBorder};
  overflow-y: auto;
  padding-bottom: ${p => (p.extraPadding ? "80px" : "10px")};
  @media (max-width: 800px) {
    width: 100%;
    padding-bottom: ${p => (p.extraPadding ? "200px" : "130px")};
  }
`;

export const CollapsableSection = styled.div`
  height: auto;
`;

export const ColorBlock = styled.div`
  height: 50px;
  width: 100px;
  border-radius: 4px;
  background: ${p => p.color};
  display: block;
  padding: 5px;
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const UnsavedChanges = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid ${theme.colors.lightBorder};
`;

export const UnsavedChangesText = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  @media (max-width: 800px) {
    font-size: 14px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 15px;
`;

const mapStateToProps = state => ({
  isFetchingSettings: getIsFetchingSettings(state),
  isSavingSettings: getIsSavingSettings(state),
  isCreatingBadge: getIsCreatingBadge(state),
  settings: getSettings(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSetting,
      saveSettings,
      selectBadge,
      updateBadgeLabel,
      createBadge,
      deleteBadge
    },
    dispatch
  )
});

class SettingsBar extends Component {
  state = { editColor: false };

  saveSettings = () => {
    this.props.saveSettings(this.props.settings);
  };

  handleColorChange = color => {
    this.props.updateSetting("accent_color", color.hex);
  };

  render() {
    const settings = get(this.props, "settings");

    const settingsChanged =
      get(settings, "settingsChanged") || get(settings, "badgesChanged");

    return (
      <div>
        {settingsChanged && (
          <UnsavedChanges>
            <UnsavedChangesText>Unsaved Changes</UnsavedChangesText>
            <Button
              text="Save"
              onClick={this.saveSettings}
              isFetching={this.props.isSavingSettings > 0}
            />
          </UnsavedChanges>
        )}

        <Wrapper extraPadding={settingsChanged}>
          <Badges />

          <TitleRow style={{ marginBottom: "5px" }}>
            <Title>Accent Color</Title>

            <Text.Small
              light
              link
              extra="margin-left: 20px;"
              onClick={() =>
                this.setState({ editColor: !this.state.editColor })
              }
            >
              {this.state.editColor ? "Hide" : "Edit"}
            </Text.Small>
          </TitleRow>

          <Description>
            Choose the color of the badges and the banner in the description.
          </Description>

          {this.props.isFetchingSettings > 0 ? (
            <Badge skeleton />
          ) : (
            <React.Fragment>
              {this.state.editColor ? (
                <ChromePicker
                  disableAlpha
                  color={settings.accent_color}
                  onChangeComplete={this.handleColorChange}
                />
              ) : (
                <ColorBlock color={settings.accent_color}>&nbsp;</ColorBlock>
              )}
            </React.Fragment>
          )}

          <Title style={{ marginTop: "20px", marginBottom: "5px" }}>
            Other Settings
          </Title>

          <Description>
            Settings that affect the visual design of your product description.
          </Description>

          <SettingsOptions />
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SettingsBar)
);
