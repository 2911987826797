export const badgeChoices = [
  "3d_box_2.svg",
  "bar_code.svg",
  "bill.svg",
  "box.svg",
  "calculator.svg",
  "calendar.svg",
  "card.svg",
  "cards.svg",
  "cash_register.svg",
  "closed_3d_box.svg",
  "code_scanner.svg",
  "coin.svg",
  "coins.svg",
  "comment.svg",
  "delivery.svg",
  "delivery_van.svg",
  "favs.svg",
  "filter.svg",
  "filter_2.svg",
  "gift.svg",
  "grid_view.svg",
  "hanger.svg",
  "home.svg",
  "info.svg",
  "invoice.svg",
  "languages.svg",
  "like.svg",
  "list_view.svg",
  "location.svg",
  "love.svg",
  "medal.svg",
  "money_bag.svg",
  "money_stack.svg",
  "offer_bill.svg",
  "open.svg",
  "open_3d_box.svg",
  "pay_by_phone.svg",
  "pay_with_card.svg",
  "piggy.svg",
  "qr_code.svg",
  "sale.svg",
  "search.svg",
  "ship_box.svg",
  "ship_box_2.svg",
  "shop.svg",
  "shopping_bag.svg",
  "shopping_bag_2.svg",
  "shopping_basket.svg",
  "shopping_cart.svg",
  "tag.svg",
  "user.svg",
  "view.svg",
  "wallet.svg",
  "wallet_2.svg"
];
