import styled from "styled-components";
import { theme } from "modules/Theme";

export const Small = styled.div`
  font-size: 16px;
  color: ${theme.colors.medDarkGrey};
  ${props =>
    props.medGrey && "color:" + theme.colors.medGrey + ";"} font-weight: 500;
  ${props => props.light && "font-weight: 300;"}
  ${props => props.bold && "font-weight: 700;"}
  ${props => props.white && `color: ${theme.colors.white};`}
  ${props => props.center && "text-align: center;"}
  ${props => props.pointer && "cursor:pointer;"}
  ${props => props.green && `color: ${theme.colors.main};`}
  ${props =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
  ${props => props.extra};
  
`;

export const Medium = styled.div`
  font-size: 20px;
  color: ${theme.colors.medDarkGrey};
  ${props =>
    props.medGrey && "color:" + theme.colors.medGrey + ";"} font-weight: 500;
  ${props => props.light && "font-weight: 300;"}
  ${props => props.bold && "font-weight: 700;"}
  ${props => props.white && `color: ${theme.colors.white};`}
  ${props => props.center && "text-align: center;"}
  ${props => props.pointer && "cursor:pointer;"}
  ${props => props.green && `color: ${theme.colors.main};`}
  ${props =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
  ${props => props.extra};

`;

export const Large = styled.div`
  font-size: 25px;
  color: ${theme.colors.medDarkGrey};
  ${props =>
    props.medGrey && "color:" + theme.colors.medGrey + ";"} font-weight: 500;
  ${props => props.light && "font-weight: 300"};
  ${props => props.bold && "font-weight: 700"};
  ${props => props.white && `color: ${theme.colors.white};`}
  ${props => props.center && "text-align: center;"}
  ${props => props.pointer && "cursor:pointer;"}
  ${props => props.green && `color: ${theme.colors.main}`};
  ${props =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
    ${props => props.extra && props.extra};
`;

// Default Medium
const Text = Medium;

Text.Small = Small;
Text.Large = Large;

export default Text;
