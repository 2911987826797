import React, { Component } from "react";
import BadgeSelect from "./BadgeSelect";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { get } from "lodash";
import Badge, { Icon } from "./Badge";
import Text from "components/Text";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Description } from "./settings/SettingsBar";

import {
  selectBadge,
  updateBadgeLabel,
  createBadge,
  deleteBadge
} from "Actions";
import {
  getSettings,
  getIsCreatingBadge,
  getIsFetchingSettings
} from "Selectors";

const mapStateToProps = state => ({
  isFetchingSettings: getIsFetchingSettings(state),
  isCreatingBadge: getIsCreatingBadge(state),
  settings: getSettings(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      selectBadge,
      updateBadgeLabel,
      createBadge,
      deleteBadge
    },
    dispatch
  )
});

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  font-size: 13px;
`;

export const BadgesRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  flex-direction: ${p => (p.edit ? "column" : "row")};
`;

class Badges extends Component {
  state = { badgeSelect: false, edit: false };

  createBadge = (icon, label) => {
    this.props.createBadge(icon, label).then(() => {
      this.setState({ badgeSelect: false });
    });
  };

  render() {
    const settings = get(this.props, "settings");
    const badges = get(this.props, ["settings", "badges"]);

    if (this.state.badgeSelect) {
      return (
        <BadgeSelect
          createBadge={this.createBadge}
          isCreatingBadge={this.props.isCreatingBadge}
          hide={() => this.setState({ badgeSelect: false })}
        />
      );
    }

    return (
      <React.Fragment>
        <TitleRow style={{ marginBottom: "5px" }}>
          <Title>Badges</Title>
          <Text.Small
            light
            link
            extra="margin-left: 20px;"
            onClick={() => this.setState({ edit: !this.state.edit })}
          >
            {this.state.edit ? "Hide" : "Edit"}
          </Text.Small>
        </TitleRow>

        <Description>
          Add icon badges to make your description stand out!
        </Description>

        <div style={{ display: "flex" }}>
          {this.state.edit && (
            <Text.Small
              light
              link
              extra="margin-top: 10px; margin-bottom: 15px;"
              onClick={() => this.setState({ badgeSelect: true })}
            >
              New Badge +
            </Text.Small>
          )}
        </div>

        {this.props.isFetchingSettings > 0 && (
          <React.Fragment>
            <Badge skeleton />
            <Badge skeleton />
            <Badge skeleton />
          </React.Fragment>
        )}
        <BadgesRow edit={this.state.edit}>
          {badges &&
            badges.map(badge => {
              const iconPrefix =
                "https://dc-imports.s3.ca-central-1.amazonaws.com/descriptify";

              let badgeIcon = badge.icon;

              if (this.state.edit) {
                if (settings.icon_color === "LIGHT") {
                  badgeIcon = `${badgeIcon.split(".svg")[0]}-white.svg`;
                }

                return (
                  <Badge
                    src={`${iconPrefix}/${badgeIcon}`}
                    badge={badge}
                    badgeColor={settings.accent_color}
                    updateLabel={label =>
                      this.props.updateBadgeLabel(badge.id, label)
                    }
                    remove={() => this.props.deleteBadge(badge.id)}
                  />
                );
              } else {
                return (
                  <Icon
                    key={badge.id}
                    src={`${iconPrefix}/${badgeIcon}`}
                    color="transparent"
                  />
                );
              }
            })}
        </BadgesRow>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Badges);
