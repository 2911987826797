import { Provider } from "react-redux";
import React, { Component } from "react";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import createBrowserHistory from "history/createBrowserHistory";

// import HttpsRedirect from "react-https-redirect";
import { Switch, Router, Route } from "react-router-dom";

// GUEST
import { Landing, Login, Terms, Privacy, Contact } from "views/guest";

// STORE
import Store from "views/store/Store";

export const history = createBrowserHistory();

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(thunkMiddleware))
    : applyMiddleware(thunkMiddleware)
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route path="/dashboard" component={Store} />
            <Route path="/upgrade" component={Store} />
            <Route path="/login" component={Login} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/contact" component={Contact} />
            <Route path="/" component={Landing} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
