import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LandingBanner from "./LandingBanner";
import LandingFooter from "./LandingFooter";
import styled from "styled-components";
import SupportForm from "./SupportForm";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const ContentWhite = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px;
`;

export const ContentGreyFlat = styled.div`
  background: #fafafa;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 100px;
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: ${p => (p.reverse ? "wrap-reverse" : "wrap")};
    padding: 20px;
    justify-content: center;
  }
`;

class Contact extends Component {
  render() {
    return (
      <Wrapper>
        <LandingBanner
          simple
          headline="Contact Us"
          subtitle="Questions? Please get in touch at support@dropcommerce.co and we'll respond within 1 business day."
        />

        <ContentWhite>
          <Content>
            <div style={{ margin: "0 auto" }}>
              <SupportForm />
            </div>
          </Content>
        </ContentWhite>

        <LandingFooter />
      </Wrapper>
    );
  }
}

export default withRouter(Contact);
