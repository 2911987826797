import React, { Component } from "react";
import { ReactComponent as Logo } from "images/descriptify-logo.svg";
import menuIcon from "images/menu-icon.png";
import closeIcon from "images/close-icon-black.svg";
import { withRouter } from "react-router-dom";
import { Row } from "components/Layout";
import styled from "styled-components";
import { theme } from "modules/Theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const HeaderLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
    margin-top: 0px;
  }
`;

export const BannerColor = styled.div`
  background: linear-gradient(to bottom, #49c0b0 0%, #42b5b4 100%);
  padding-bottom: 200px;
  ${p => p.simple && "padding-bottom: 140px;"}
  width: 100%;
  clip-path: polygon(
    0 0,
    100% 0,
    100% ${p => (p.simple ? "85%" : "70%")},
    0 100%
  );
  @media (max-width: 800px) {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    padding-bottom: 100px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledLogo = styled(Logo)`
  height: 60px;
  cursor: pointer;
  @media (max-width: 800px) {
    height: 40px;
  }
`;

export const WatchGuy = styled.img`
  height: 320px;
  @media (max-width: 800px) {
    height: 230px;
    margin-bottom: 15px;
  }
`;

export const HeaderLink = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin: 10px;
  cursor: pointer;
  color: ${theme.colors.white};
`;

export const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  transition: 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 23px;
  padding-top: 30px;
`;

export const MenuIcon = styled.img`
  display: none;
  cursor: pointer;
  @media (max-width: 800px) {
    display: initial;
    height: 18px;
  }
  &:hover {
    opacity: 0.6;
  }
  transition: 0.5s;
`;

export const MenuMobileOption = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  color: #1f302f;
`;

export const HeaderButton = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin: 10px;
  cursor: pointer;
  color: ${theme.colors.white};
  border-radius: 30px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid white;
  &:hover {
    background: #2d968d;
    color: ${theme.colors.white};
  }
  transition: 0.5s;
`;

export const CTAButton = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-right: 30px;
  cursor: pointer;
  color: ${props => (props.hollow ? "white" : "#2d968d")};
  border-radius: 30px;
  padding: 15px;
  width: 300px;
  text-align: center;
  border: 2px solid white;
  background: ${props => (props.hollow ? "transparent" : "white")};
  ${props =>
    props.green &&
    "background: #49c0b0; border: 2px solid #49c0b0; color: white;"}
  ${props =>
    props.greenHollow &&
    "background: transparent; border: 2px solid #49c0b0; color: #49c0b0;"}
  &:hover {
    background: #2d968d;
    border: 2px solid ${props => (props.hollow ? "white" : "#2d968d")};
    color: ${theme.colors.white};
  }
  transition: 0.5s;
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Headline = styled.div`
  font-size: 70px;
  font-weight: 700;
  margin-top: 120px;
  ${p => p.simple && "margin: 0 auto; margin-top: 70px; font-size: 50px;"}
  ${p => p.standalone && "margin-top: 70px; font-size: 60px;"}
  color: ${theme.colors.white};
  @media (max-width: 800px) {
    font-size: 40px;
    margin-top: 30px;
    font-weight: 500;
  }
`;

export const SpecialOfferText = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin-top: 30px;
  color: ${theme.colors.white};
  @media (max-width: 800px) {
    width: 100%;
    font-size: 14px;
    margin-top: 20px;
  }
`;

export const Subtitle = styled.div`
  font-size: 27px;
  font-weight: 300;
  margin-top: 30px;
  color: ${theme.colors.white};
  ${p => p.simple && "margin: 0 auto; margin-top: 25px; font-size: 23px;"}
  @media (max-width: 800px) {
    width: 100%;
    font-size: 20px;
    margin-top: 20px;
  }
`;

export const ContentGrey = styled.div`
  background: #fafafa;
  padding-bottom: 60px;
  width: 100%;
  clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%);
  padding-top: 200px;
  @media (max-width: 800px) {
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
    padding-top: 60px;
  }
`;

export const ContentWhite = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px;
`;

export const ContentGreyFlat = styled.div`
  background: #fafafa;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 100px;
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: ${p => (p.reverse ? "wrap-reverse" : "wrap")};
    padding: 20px;
    justify-content: center;
  }
`;

export const ContentHeader = styled.div`
  font-size: 50px;
  font-weight: 700;
  color: #1f302f;
  @media (max-width: 800px) {
    font-size: 30px;
  }
`;

export const ContentDescription = styled.div`
  font-size: 22px;
  font-weight: 300;
  margin-top: 30px;
  color: #1f302f;
  opacity: 0.7;
  @media (max-width: 800px) {
    font-size: 16px;
    margin-top: 22px;
  }
`;

export const ProductImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-around;
  }
`;

export const ProductImage = styled.img`
  height: 220px;
  margin: 8px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  @media (max-width: 800px) {
    height: 150px;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

export const ContentColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-left: 50px;
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const ContentColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-right: 50px;
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const CTAButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

class LandingBanner extends Component {
  state = { showMenu: false };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  goTo = (link, internal = true) => {
    if (internal) {
      this.props.history.push(link);
    } else {
      window.open(link);
    }
  };

  showMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    return (
      <BannerColor simple={this.props.simple}>
        <Banner>
          <Header>
            <StyledLogo onClick={() => this.props.history.push("/")} />
            <MenuIcon src={menuIcon} onClick={this.showMenu} />
            {this.state.showMenu && (
              <MenuMobile>
                <Row style={{ justifyContent: "flex-end", width: "100%" }}>
                  <MenuIcon src={closeIcon} onClick={this.showMenu} />
                </Row>
                {/* <MenuMobileOption
                  onClick={() => this.props.history.push("/pricing")}
                >
                  PRICING
                </MenuMobileOption> */}
                <MenuMobileOption
                  onClick={() =>
                    this.goTo(
                      "https://help.dropcommerce.co/en/collections/1958572-descriptify",
                      false
                    )
                  }
                >
                  FAQ
                </MenuMobileOption>
                <MenuMobileOption
                  onClick={() => this.props.history.push("/contact")}
                >
                  CONTACT
                </MenuMobileOption>
                <MenuMobileOption onClick={this.showMenu}>
                  SIGN UP
                </MenuMobileOption>
                <MenuMobileOption
                  onClick={() => this.props.history.push("/supplierlogin")}
                >
                  SUPPLIERS
                </MenuMobileOption>
              </MenuMobile>
            )}
            <HeaderLinksWrapper>
              {/* <HeaderLink onClick={() => this.props.history.push("/pricing")}>
                PRICING
              </HeaderLink> */}
              <HeaderLink
                onClick={() =>
                  this.goTo(
                    "https://help.dropcommerce.co/en/collections/1958572-descriptify",
                    false
                  )
                }
              >
                FAQ
              </HeaderLink>
              <HeaderLink onClick={() => this.props.history.push("/contact")}>
                CONTACT
              </HeaderLink>
              <HeaderButton
                onClick={() =>
                  window.open("https://apps.shopify.com/descriptify")
                }
              >
                SIGN UP
              </HeaderButton>
            </HeaderLinksWrapper>
          </Header>
          <Headline simple={this.props.simple}>{this.props.headline}</Headline>
          {this.props.subtitle && (
            <Subtitle simple={this.props.simple}>
              {this.props.subtitle}
            </Subtitle>
          )}
          {!this.props.simple && (
            <CTAButtonsWrapper>
              <CTAButton
                onClick={() =>
                  window.open("https://apps.shopify.com/descriptify")
                }
              >
                {this.props.installText || "INSTALL ON SHOPIFY"}
              </CTAButton>
            </CTAButtonsWrapper>
          )}
        </Banner>
      </BannerColor>
    );
  }
}

export default withRouter(LandingBanner);
