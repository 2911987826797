import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { ReactComponent as Logo } from "images/descriptify-logo-green.svg";
import { getUrlParameter } from "modules/Format";
import { Row } from "components/Layout";
import SelectDropdown from "components/SelectDropdown";
import { isEmpty, get } from "lodash";
import {
  fetchProducts,
  toggleProduct,
  toggleAll,
  clearSelectedProducts,
  fetchDescription,
  updateDescription,
  saveSettings,
  resetDescription,
  setPlan,
  editDescription
} from "Actions";
import {
  getIsFetching,
  getProducts,
  getSettings,
  getIsFetchingMultiple,
  getPagination,
  getIsUpdatingDescription,
  getStore,
  getCollections
} from "Selectors";
import Product from "../components/Product";
import Button from "components/Button";
import Popup from "components/Popup";
import SettingsBar from "../components/settings/SettingsBar";
import Description from "../components/Description";
import Cookies from "js-cookie";

export const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 12px;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 800px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StyledLogo = styled(Logo)`
  height: 40px;
  @media (max-width: 800px) {
    height: 35px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${theme.colors.medDarkGrey};
  margin-bottom: 30px;
`;

export const Link = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  margin-left: 20px;
  cursor: pointer;
`;

export const ClickToSelectText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  ${p =>
    p.link &&
    `
    color: ${theme.colors.main};
    margin-left: 20px;
    cursor: pointer;
  `}
  @media (max-width: 800px) {
    font-size: 13px;
  }
`;

export const ProductSection = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 800px) {
    ${p =>
      p.showMobileSettings &&
      `
      display: none;
  `}
  }
`;

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 100px;
  /* @media (max-width: 800px) {
    padding-bottom: 140px;
  } */
`;

export const ProductsWrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const SettingsBarDesktop = styled.div`
  display: flex;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const SettingsBarMobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Search = styled.input`
  background: ${theme.colors.white};
  color: ${theme.colors.medDarkGrey};
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  width: 230px;
  font-size: 15px;
  font-weight: 300;
  margin-right: 15px;
  ${props => props.extraStyle};
  -webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
`;

export const ShowFiltersText = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.main};
  width: 200px;
  cursor: pointer;
`;

export const Titlebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  @media (max-width: 800px) {
    padding: 15px;
  }
`;

export const PaginationButton = styled.div`
  padding: 10px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  color: ${theme.colors.medDarkGrey};
  cursor: pointer;
  &:hover {
    color: ${theme.colors.darkGrey};
  }
`;

export const MobileTabs = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    width: 100%;
  }
`;

export const MobileTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  font-weight: 500;
  width: calc(100% / 2);
  border-right: ${p => (p.borderRight ? "1px" : "0px")} solid
    ${theme.colors.lightBorder};
  border-bottom: 1px solid
    ${p => (p.active ? "white" : theme.colors.lightBorder)};
  color: ${p => (p.active ? theme.colors.main : theme.colors.medDarkGrey)};
`;

export const StyledButton = styled(Button)`
  @media (max-width: 800px) {
    display: none;
  }
`;

const mapStateToProps = state => ({
  isFetching: getIsFetching(state),
  products: getProducts(state),
  settings: getSettings(state),
  collections: getCollections(state),
  store: getStore(state),
  isFetchingMultiple: getIsFetchingMultiple(state),
  pagination: getPagination(state),
  isUpdatingDescription: getIsUpdatingDescription(state)
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      fetchProducts,
      toggleProduct,
      toggleAll,
      clearSelectedProducts,
      fetchDescription,
      updateDescription,
      saveSettings,
      resetDescription,
      setPlan,
      editDescription
    },
    dispatch
  )
});

class Dashboard extends Component {
  state = {
    description: null,
    isFetchingDescription: false,
    fixed: false,
    planApproved: false,
    chargeDeclined: false,
    showFixedBubble: false,
    showMobileSettings: false,
    search: "",
    vendor: "",
    collections: [],
    showFilters: false
  };

  updateCollection = collectionId => {
    this.props.fetchProducts(1, collectionId, "", "");

    this.setState({
      collections: this.state.collections.map(option => {
        return { ...option, selected: option.value === collectionId };
      })
    });
  };

  componentDidMount = () => {
    const action = getUrlParameter("action", this.props.location);

    if (action === "plan_approved") {
      this.setState({ planApproved: true });
    }

    if (action === "charge_declined") {
      this.setState({ chargeDeclined: true });
    }

    const vendor = getUrlParameter("vendor", this.props.location) || "";
    const search = getUrlParameter("search", this.props.location) || "";
    const collection = getUrlParameter("collection", this.props.location) || "";
    const page = getUrlParameter("page", this.props.location) || 1;

    this.props.fetchProducts(page, collection, search, vendor);

    this.setState({ search, vendor });
  };

  fetchProducts = (value = null, type = null) => {
    let vendor = this.state.vendor;
    let search = this.state.search;
    let collection = getUrlParameter("collection", this.props.location) || "";
    let page = getUrlParameter("page", this.props.location) || 1;

    if (type !== "pagination") {
      page = 1;
    }

    if (type === "vendor") {
      vendor = value;
      this.setState({ vendor: vendor });
    } else if (type === "pagination") {
      page = value;
    } else if (type === "collection") {
      collection = value;
    }

    this.props.fetchProducts(page, collection, search, vendor);

    this.props.history.push(
      `/dashboard?collection=${collection}&p=${page}&search=${search}&vendor=${vendor}`
    );
  };

  getDescription = (productId, fixed) => {
    // FETCH DESCRIPTION
    this.props.fetchDescription(productId, fixed).then(response => {
      if (response.description) {
        this.setState({
          description: response.description,
          isFetchingDescription: false,
          fixed: fixed
        });
      }
    });
  };

  fetchDescription = (productId, fixed, e = null) => {
    if (e) {
      e.stopPropagation();
    }

    this.setState({ isFetchingDescription: true }, () => {
      if (
        this.props.settings.settingsChanged ||
        this.props.settings.badgesChanged
      ) {
        this.props
          .saveSettings(this.props.settings)
          .then(this.getDescription(productId, fixed));
      } else {
        this.getDescription(productId, fixed);
      }
    });
  };

  updateDescription = (productId, e = null) => {
    if (e) {
      e.stopPropagation();
    }
    if (this.isPastLimit()) {
      this.props.history.push("/upgrade");
    } else {
      if (
        this.props.settings.settingsChanged ||
        this.props.settings.badgesChanged
      ) {
        this.props.saveSettings(this.props.settings).then(
          this.props.updateDescription(parseInt(productId)).then(() =>
            this.setState({
              fixed: true,
              showFixedBubble: true
            })
          )
        );
      } else {
        this.props.updateDescription(parseInt(productId)).then(() =>
          this.setState({
            fixed: true,
            showFixedBubble: true
          })
        );
      }
    }
  };

  resetDescription = (productId, e) => {
    e.stopPropagation();
    this.props.resetDescription(productId);
  };

  logout = () => {
    Cookies.remove("accessToken");
    this.props.history.push(`/`);
    // this.props.setError("");
  };

  fixMultiple = () => {
    if (this.isPastLimit()) {
      this.props.history.push("/upgrade");
    } else {
      const productIds = this.props.products
        .filter(product => product.selected)
        .map(product => {
          return product.id;
        });

      if (
        this.props.settings.settingsChanged ||
        this.props.settings.badgesChanged
      ) {
        this.props
          .saveSettings(this.props.settings)
          .then(this.props.updateDescription(null, productIds));
      } else {
        this.props.updateDescription(null, productIds);
      }
    }
  };

  upgrade = () => {
    this.props.setPlan().then(response => {
      if (response.plan_auth_url) {
        window.location = response.plan_auth_url;
      }
    });
  };

  onEnterPress = e => {
    if (e.key === "Enter") {
      this.fetchProducts();
    }
  };

  clearFilters = () => {
    this.props.fetchProducts(1, "", "", "");
    this.props.history.push(`/dashboard?page=1`);
    this.setState({ search: "", vendor: "" });
  };

  isPastLimit = () => {
    // const productCount = get(this.props, ["store", "product_count"]);
    // const trialDays = get(this.props, ["store", "trial_days"]);
    // const paidInTrial = get(this.props, ["store", "paid_in_trial"]);

    // return !paidInTrial && trialDays < 1 && productCount > 15;
    return false;
  };

  render() {
    let selectedProducts = 0;

    this.props.products.forEach(product => {
      if (product.selected) selectedProducts += 1;
    });

    let currentCollection = "";

    let collectionInURL =
      getUrlParameter("collection", this.props.location) || "";

    let searchInURL = getUrlParameter("search", this.props.location) || "";

    let vendorInURL = getUrlParameter("vendor", this.props.location) || "";

    this.props.collections.forEach(collection => {
      if (
        collection.selected ||
        parseInt(collectionInURL) == collection.value
      ) {
        currentCollection = collection.label;
      }
    });

    const selectedText = `Fix ${selectedProducts} Selected ${
      selectedProducts === 1 ? "Product" : "Products"
    }`;

    const filtersHaveValue =
      searchInURL !== "" || vendorInURL !== "" || currentCollection !== "";

    return (
      <Wrapper>
        {this.state.planApproved && (
          <Popup
            title="Succesfully Upgraded!"
            hide={() => this.setState({ planApproved: false })}
          />
        )}

        {this.state.chargeDeclined && (
          <Popup
            title="Error! Did you decline the charge in Shopify?"
            hide={() => this.setState({ chargeDeclined: false })}
            action={() => this.props.history("/upgrade")}
            actionText="Try Again"
          />
        )}
        {/* 
        {this.state.showFixedBubble && (
          <Popup
            title="Description Updated In Shopify!"
            hide={() => this.setState({ showFixedBubble: false })}
          />
        )} */}

        <Header>
          <StyledLogo />

          <LinksWrapper>
            <StyledButton
              text="How To Use Descriptify"
              buttonStyle="margin-right: 15px"
              onClick={() =>
                window.open("https://www.youtube.com/watch?v=cZ_yyx--jow")
              }
            />
            <Link
              onClick={() =>
                window.open(
                  "https://help.dropcommerce.co/en/collections/1958572-descriptify"
                )
              }
            >
              Support
            </Link>
            <Link onClick={this.logout}>Logout</Link>
          </LinksWrapper>
        </Header>

        <MobileTabs>
          <MobileTab
            active={this.state.showMobileSettings}
            onClick={() => this.setState({ showMobileSettings: true })}
            borderRight
          >
            Settings
          </MobileTab>
          <MobileTab
            active={!this.state.showMobileSettings}
            onClick={() => this.setState({ showMobileSettings: false })}
          >
            Products
          </MobileTab>
        </MobileTabs>

        <Container>
          <SettingsBarDesktop>
            <SettingsBar />
          </SettingsBarDesktop>

          {this.state.showMobileSettings && (
            <SettingsBarMobile>
              <SettingsBar />
            </SettingsBarMobile>
          )}

          <ProductSection showMobileSettings={this.state.showMobileSettings}>
            {this.state.description || this.state.isFetchingDescription ? (
              <Description
                description={this.state.description}
                settingsChanged={
                  this.props.settings.settingsChanged ||
                  this.props.settings.badgesChanged
                }
                fetching={this.state.isFetchingDescription}
                hideDescription={() => this.setState({ description: null })}
                updateDescription={this.updateDescription}
                storeUrl={this.props.store.url}
                editDescription={this.props.editDescription}
                fetchDescription={this.fetchDescription}
                isUpdatingDescription={this.props.isUpdatingDescription}
                fixed={this.state.fixed}
              />
            ) : (
              <MainDiv>
                <Titlebar>
                  <Row>
                    {selectedProducts > 0 && (
                      <Button
                        text={selectedText}
                        buttonStyle="margin-right: 15px"
                        isFetching={this.props.isFetchingMultiple}
                        onClick={this.fixMultiple}
                      />
                    )}
                    {selectedProducts > 0 && (
                      <Button
                        text="Clear Selections"
                        type="main-hollow"
                        buttonStyle="margin-right: 15px"
                        onClick={this.props.clearSelectedProducts}
                      />
                    )}
                  </Row>
                  <Row style={{ justifyContent: "flex-start", width: "100%" }}>
                    {selectedProducts === 0 && (
                      <ClickToSelectText>
                        Select products to bulk edit descriptions
                      </ClickToSelectText>
                    )}

                    <ClickToSelectText
                      link
                      onClick={() => this.props.toggleAll(true)}
                    >
                      Select all
                    </ClickToSelectText>
                  </Row>
                </Titlebar>

                <FiltersRow>
                  <Search
                    placeholder="Search exact product title"
                    value={this.state.search}
                    onKeyPress={e => this.onEnterPress(e)}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  <Search
                    placeholder="Search exact vendor name"
                    value={this.state.vendor}
                    onKeyPress={e => this.onEnterPress(e)}
                    onChange={e => this.setState({ vendor: e.target.value })}
                  />
                  <SelectDropdown
                    name="Filter by collection:"
                    options={this.props.collections}
                    onClick={collectionId =>
                      this.fetchProducts(collectionId, "collection")
                    }
                    width="330px"
                    toggle
                    current={currentCollection}
                  />
                  <Button text="Search" onClick={this.fetchProducts} />
                  {filtersHaveValue && (
                    <Button
                      text="Clear Filters"
                      type="main-hollow"
                      buttonStyle="margin-left: 10px;"
                      onClick={this.clearFilters}
                    />
                  )}
                </FiltersRow>

                {!isEmpty(this.props.pagination) && (
                  <Row style={{ padding: "15px" }}>
                    {this.props.pagination.page > 1 && (
                      <PaginationButton
                        style={{ marginRight: "10px" }}
                        onClick={() =>
                          this.fetchProducts(
                            this.props.pagination.page - 1,
                            "pagination"
                          )
                        }
                      >
                        Previous Page
                      </PaginationButton>
                    )}
                    {this.props.pagination.next && (
                      <PaginationButton
                        onClick={() =>
                          this.fetchProducts(
                            this.props.pagination.page + 1,
                            "pagination"
                          )
                        }
                      >
                        Next Page
                      </PaginationButton>
                    )}
                  </Row>
                )}

                <ProductsWrapper>
                  {this.props.isFetching > 0
                    ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                        <Product skeleton key={num} />
                      ))
                    : this.props.products
                        .filter(product => product && product.image)
                        .map(product => (
                          <Product
                            key={product.id}
                            product={product}
                            toggle={() => this.props.toggleProduct(product.id)}
                            updateDescription={this.updateDescription}
                            resetDescription={this.resetDescription}
                            fixTitle={this.props.settings.fix_product_title}
                            titleLimit={this.props.settings.product_title_limit}
                            fetchDescription={e =>
                              this.fetchDescription(
                                product.id,
                                product.fixed,
                                e
                              )
                            }
                          />
                        ))}
                </ProductsWrapper>
              </MainDiv>
            )}
          </ProductSection>
        </Container>
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
