import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import questionMark from "images/question-mark-green.svg";

export const QuestionMark = styled.img`
  margin-left: 10px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

export const HintPopup = styled.div`
  position: absolute;
  padding: 10px;
  top: 20px;
  ${p => p.left && "right: 20px;"}
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  z-index: 9999;
  -webkit-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 3px 21px -5px rgba(0, 0, 0, 0.25);
  /* max-width: 350px; */
  max-width: 100%;
  min-width: ${p => (p.narrow ? "200px" : "250px")};
`;

class Hint extends Component {
  state = { showHint: false };
  render() {
    return (
      <div style={{ position: "relative" }}>
        <QuestionMark
          src={questionMark}
          onMouseEnter={() => this.setState({ showHint: true })}
          onMouseLeave={() => this.setState({ showHint: false })}
        />
        {this.state.showHint && (
          <HintPopup left={this.props.left} narrow={this.props.narrow}>
            {this.props.hint}
          </HintPopup>
        )}
      </div>
    );
  }
}

export default Hint;
