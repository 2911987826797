import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Dashboard, Upgrade } from "./views";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchSettings } from "Actions";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators({ fetchSettings }, dispatch)
});

class Store extends Component {
  componentDidMount = () => {
    this.props.fetchSettings();
  };
  render() {
    return (
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/upgrade" component={Upgrade} />
        <Route path="/" component={Dashboard} />
      </Switch>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);
