import React, { Component } from "react";
import styled from "styled-components";
import { theme } from "modules/Theme";
import xCircle from "images/x-circle.svg";
import MDSpinner from "react-md-spinner";
import SkeletonLoader from "components/SkeletonLoader";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  padding: 10px;
  margin-left: 10px;
  border: 1px solid ${theme.colors.lightBorder};
  border-radius: 4px;
  margin-right: 10px;
  font-size: 14px;
`;

export const IconWrapper = styled.div`
  height: 55px;
  width: 55px;
  margin: 5px;
  border-radius: 50%;
  background: ${p => p.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled.img`
  height: 35px;
  width: 35px;
  margin: 12px;
`;

export const XCircle = styled.img`
  height: 30px;
  width: 30px;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const BadgeSkeleton = styled(SkeletonLoader)`
  height: 30px;
  width: 240px;
  max-width: 240px;
  border-radius: 4px;
  margin-bottom: 15px;
`;

class Badge extends Component {
  render() {
    if (this.props.skeleton) {
      return (
        <Wrapper>
          <BadgeSkeleton />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <IconWrapper color={this.props.badgeColor}>
          <Icon src={this.props.src} />
        </IconWrapper>
        <Input
          value={this.props.badge.label}
          onChange={e => this.props.updateLabel(e.target.value)}
        />
        {this.props.badge.isDeleting ? (
          <MDSpinner singleColor="#363636" size="20px" />
        ) : (
          <XCircle src={xCircle} onClick={this.props.remove} />
        )}
      </Wrapper>
    );
  }
}

export default Badge;
