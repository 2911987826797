import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import beforeAfter from "images/before-after-example.png";
import badgeCreation from "images/badge-creation.png";
import { theme } from "modules/Theme";
import LandingBanner from "./LandingBanner";
import LandingFooter from "./LandingFooter";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { getUrlParameter } from "modules/Format";
import MDSpinner from "react-md-spinner";
import { setPlanApproval } from "Actions";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      setPlanApproval
    },
    dispatch
  )
});

class LandingContent extends Component {
  state = { showMenu: false, fetching: false };

  componentWillMount = () => {
    const chargeId = getUrlParameter("charge_id", this.props.location);
    const type = getUrlParameter("type", this.props.location);

    if (chargeId) {
      this.setState({ fetching: true });
      this.props.setPlanApproval(chargeId, type).then(response => {
        if (response.success) {
          this.props.history.push("/dashboard?action=plan_approved");
        } else {
          this.props.history.push("/dashboard?action=charge_declined");
        }
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  goTo = (link, internal = true) => {
    if (internal) {
      this.props.history.push(link);
    } else {
      window.open(link);
    }
  };

  showMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  render() {
    return (
      <Wrapper>
        {this.state.fetching ? (
          <LoadingWrapper>
            <LoadingText>Loading your account....</LoadingText>
            <MDSpinner singleColor="#363636" size="80px" />
          </LoadingWrapper>
        ) : (
          <React.Fragment>
            <LandingBanner
              headline="Better Product Descriptions"
              subtitle="Sell more products and spend less time editing product descriptions"
            />

            <ContentWhite>
              <Content>
                <ContentColumnRight>
                  <ContentHeader>
                    Professional Descriptions In Seconds
                  </ContentHeader>
                  <ContentDescription>
                    Instantly overhaul your product descriptions with a
                    beautifully designed layout by our graphic design team.
                  </ContentDescription>
                  <CTAButton
                    greenHollow
                    onClick={this.scrollToTop}
                    style={{ marginTop: "30px" }}
                  >
                    START 7 DAY FREE TRIAL
                  </CTAButton>
                </ContentColumnRight>
                <BeforeAfter src={beforeAfter} />
              </Content>
            </ContentWhite>
            <ContentGrey>
              <Content>
                <BadgeCreation src={badgeCreation} />
                <ContentColumnLeft>
                  <ContentHeader>Create Custom Badges</ContentHeader>
                  <ContentDescription>
                    Choose from dozens of high quality icons and customize the
                    color and text to add a special flair to your product
                    descriptions!
                  </ContentDescription>
                  <CTAButton
                    green
                    onClick={this.scrollToTop}
                    style={{ marginTop: "30px" }}
                  >
                    START 7 DAY FREE TRIAL
                  </CTAButton>
                </ContentColumnLeft>
              </Content>
            </ContentGrey>

            <ContentGreyFlat>
              <LandingFooter />
            </ContentGreyFlat>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LandingContent)
);

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
`;

export const LoadingText = styled.div`
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 30px;
  color: ${theme.colors.medDarkGrey};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const HeaderLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
    margin-top: 0px;
  }
`;

export const BannerColor = styled.div`
  background: linear-gradient(to bottom, #49c0b0 0%, #42b5b4 100%);
  padding-bottom: 200px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  @media (max-width: 800px) {
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
    padding-bottom: 100px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Logo = styled.img`
  height: 60px;
  @media (max-width: 800px) {
    height: 40px;
  }
`;

export const BeforeAfter = styled.img`
  height: 380px;
  @media (max-width: 800px) {
    height: 280px;
    margin-bottom: 15px;
  }
`;

export const BadgeCreation = styled.img`
  height: 380px;
  width: 500px;
  border-radius: 15px;
  padding: 20px;
  background: white;
  @media (max-width: 800px) {
    height: 350px;
    margin-bottom: 15px;
  }
`;

export const HeaderLink = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin: 10px;
  cursor: pointer;
  color: white;
`;

export const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  transition: 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 23px;
  padding-top: 30px;
`;

export const MenuIcon = styled.img`
  display: none;
  cursor: pointer;
  @media (max-width: 800px) {
    display: initial;
    height: 18px;
  }
  &:hover {
    opacity: 0.6;
  }
  transition: 0.5s;
`;

export const MenuMobileOption = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  color: #1f302f;
`;

export const HeaderButton = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin: 10px;
  cursor: pointer;
  color: white;
  border-radius: 30px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid white;
  &:hover {
    background: #2d968d;
    color: white;
  }
  transition: 0.5s;
`;

export const CTAButton = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-right: 30px;
  cursor: pointer;
  color: ${props => (props.hollow ? "white" : "#2d968d")};
  border-radius: 30px;
  padding: 15px;
  width: 300px;
  text-align: center;
  border: 2px solid white;
  background: ${props => (props.hollow ? "transparent" : "white")};
  ${props =>
    props.green &&
    "background: #49c0b0; border: 2px solid #49c0b0; color: white;"}
  ${props =>
    props.greenHollow &&
    "background: transparent; border: 2px solid #49c0b0; color: #49c0b0;"}
  &:hover {
    background: #2d968d;
    border: 2px solid ${props => (props.hollow ? "white" : "#2d968d")};
    color: white;
  }
  transition: 0.5s;
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const Headline = styled.div`
  font-size: 70px;
  font-weight: 700;
  margin-top: 120px;
  color: white;
  @media (max-width: 800px) {
    font-size: 40px;
    margin-top: 30px;
    font-weight: 500;
  }
`;

export const Subtitle = styled.div`
  font-size: 27px;
  font-weight: 300;
  margin-top: 30px;
  color: white;
  @media (max-width: 800px) {
    width: 100%;
    font-size: 20px;
    margin-top: 20px;
  }
`;

export const ContentGrey = styled.div`
  background: #fafafa;
  padding-bottom: 60px;
  width: 100%;
  clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 100%);
  padding-top: 200px;
  @media (max-width: 800px) {
    clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
    padding-top: 60px;
  }
`;

export const ContentWhite = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px;
`;

export const ContentGreyFlat = styled.div`
  background: #fafafa;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 100px;
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: ${p => (p.reverse ? "wrap-reverse" : "wrap")};
    padding: 20px;
    justify-content: center;
  }
`;

export const ContentHeader = styled.div`
  font-size: 50px;
  font-weight: 700;
  color: #1f302f;
  @media (max-width: 800px) {
    font-size: 30px;
  }
`;

export const ContentDescription = styled.div`
  font-size: 22px;
  font-weight: 300;
  margin-top: 30px;
  color: #1f302f;
  opacity: 0.7;
  @media (max-width: 800px) {
    font-size: 16px;
    margin-top: 22px;
  }
`;

export const ProductImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: space-around;
  }
`;

export const ProductImage = styled.img`
  height: 220px;
  margin: 8px;
  border-radius: 10px;
  border: 1px solid #e3e3e3;
  @media (max-width: 800px) {
    height: 150px;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

export const ContentColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-left: 50px;
  @media (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const ContentColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-right: 50px;
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`;

export const CTAButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
