import { get } from "lodash";

const getLocalState = state => get(state, "base");

export const getIsFetching = state => getLocalState(state).isFetching;
export const getPagination = state => getLocalState(state).pagination;
export const getCollections = state => getLocalState(state).collections;
export const getIsCreatingBadge = state => getLocalState(state).isCreatingBadge;
export const getIsFetchingMultiple = state =>
  getLocalState(state).isFetchingMultiple;
export const getIsFetchingSettings = state =>
  getLocalState(state).isFetchingSettings;
export const getIsSavingSettings = state =>
  getLocalState(state).isSavingSettings;
export const getIsUpdatingDescription = state =>
  getLocalState(state).isUpdatingDescription;
export const getSettings = state => getLocalState(state).settings;
export const getProducts = state => getLocalState(state).products;
export const getStore = state => getLocalState(state).store;
