import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LandingBanner from "./LandingBanner";
import LandingFooter from "./LandingFooter";
import styled from "styled-components";
import Button from "components/Button";

import { PrivacyTitle, PrivacySection, PrivacyContainer } from "./LandingStyle";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

export const ContentWhite = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 0px;
`;

export const ContentGreyFlat = styled.div`
  background: #fafafa;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 100px;
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    flex-wrap: ${p => (p.reverse ? "wrap-reverse" : "wrap")};
    padding: 20px;
    justify-content: center;
  }
`;

class Terms extends Component {
  render() {
    return (
      <Wrapper>
        <LandingBanner
          simple
          headline="Terms of use"
          subtitle="Please ensure you agree before using Descriptify."
        />

        <ContentWhite>
          <Content>
            <PrivacyContainer>
              <PrivacyTitle>On The Use Of www.descriptify.co</PrivacyTitle>
              <PrivacySection>
                Your access to and use of www.Descriptify.co and the Descriptify
                mobile application software (collectively referred to as the
                “Site”), owned and operated by Descriptify (“Descriptify”, “we”
                or “us”), is expressly conditioned upon your acceptance of these
                Terms and Conditions of Use (“Terms”). You shall not use the
                Site for any purpose that is unlawful or prohibited by these
                Terms. By using the Site you agree to fully accept these Terms.
                If you do not accept these Terms you must immediately stop using
                the Site.
              </PrivacySection>
              <PrivacyTitle>Product Description and Site Content</PrivacyTitle>
              <PrivacySection>
                Descriptify is an app that fixes product descriptions for
                e-commerce / online stores. Therefore Descriptify accepts no
                legal responsibility for any damages; financial, legal or
                otherwise, that may arise from use of Shopify or selling
                products from other apps.
              </PrivacySection>
              <PrivacySection>
                While we endeavor to be as accurate as possible, Descriptify
                does not warrant that any product description or other content
                on the Site is accurate, complete, current or free from errors.
              </PrivacySection>
              <PrivacySection>
                All content on the Site is provided for informative purposes
                only and should not be construed as advice in any way. Much of
                the descriptions are obtained directly from individual suppliers
                themselves.
              </PrivacySection>
              <PrivacyTitle>Change of Use</PrivacyTitle>
              <PrivacySection>
                Descriptify reserves the right for any reason in our sole
                discretion to change or remove (temporarily or permanently) the
                Site or any part of it without notice or penalty. We may also
                impose limits on certain features of the Site or restrict your
                access to part or all of the Site without notice or penalty.
              </PrivacySection>
              <PrivacySection>
                You confirm that Descriptify, its directors, officers,
                shareholders, partners, employees, contractors, suppliers or
                agents shall not be held liable for any loss or damage you
                sustain for any such change or removal and change these Terms
                and Conditions at any time.
              </PrivacySection>
              <PrivacyTitle>Product Availability and Condition</PrivacyTitle>
              <PrivacySection>
                The availability of the products listed on the Site (“Products”)
                is subject to change without notice. We do not guarantee that
                any Product will be available for purchase, even after you have
                ordered and paid for it through the Site.
              </PrivacySection>
              <PrivacySection>
                All Products are produced, packaged and/or sealed, stored, and
                shipped by third party manufacturers. We do not manufacture,
                produce, store, or ship any of the Products listed on our Site.
                We do not provide any warranty regarding the Products, including
                but not limited to merchantability or fitness for consumption or
                any other particular purpose. It is your responsibility to read
                any and all labels or packaging to ensure that any Product is
                safe for your sale, use, or consumption.
              </PrivacySection>
              <PrivacySection>
                Descriptify, its principals, directors, officers, shareholders,
                partners, employees, contractors, suppliers or agents shall not
                be held liable for any loss or damage arising from your
                purchase, use, or consumption of any Products, including but not
                limited to spoiled, defective or improperly handled or produced
                Products.
              </PrivacySection>
              <PrivacyTitle>Refunds on Subscriptions</PrivacyTitle>
              <PrivacySection>
                Refunds for Descriptify subscription fees will be considered on
                a case by case basis if requested within 7 days of the payment
                date, and only if no sales have been made in the account.
              </PrivacySection>
              <PrivacyTitle>Returns</PrivacyTitle>
              <PrivacySection>
                As Descriptify does not manufacture, produce, package, or ship
                any of the products, the return policy for each individual
                product is dictated by its respective supplier.
              </PrivacySection>
              <PrivacySection>
                If any Products listed on our Site become unavailable following
                a purchase, we will request from that supplier, on your behalf,
                a substitution or a full refund for the price of that item only,
                at the sole discretion of Descriptify.
              </PrivacySection>
              <PrivacySection>
                If you are unsatisfied with a defective, deformed or improperly
                handled or packaged Product, Descriptify will contact the
                supplier to provide you a replacement Product, or a full refund,
                at our sole discretion. All complaints must be submitted to
                Descriptify by email with photo evidence, however the return
                policy is subject to that individual suppliers return policy.
              </PrivacySection>
              <PrivacyTitle>Ownership</PrivacyTitle>
              <PrivacySection>
                Excluding User Content (as defined below), all intellectual
                property rights, including but not limited to copyrights and
                trademarks, in the Site (including but not limited to the
                design, text, graphics and all software and source codes) are
                owned by or licensed to Descriptify or otherwise used by
                Descriptify as permitted by law.
              </PrivacySection>
              <PrivacyTitle>Permitted Use</PrivacyTitle>
              <PrivacySection>
                The Site is made available to you for your lawful, personal use
                only. You may use the Site only in the manner expressly
                described in these Terms and subject to all applicable laws.
                Using the Site for any other purpose or in any other manner is
                strictly prohibited. Except as provided below, you may not copy,
                imitate, publish, republish, distribute, extract, re-use,
                upload, post, transmit, modify or reproduce any part of the
                Site, in whole or in part, without the proper prior written
                consent of Descriptify. This excludes the downloading, copying
                and/or printing of pages of the Website for personal,
                non-commercial home use only.
              </PrivacySection>
              <PrivacyTitle>User Content</PrivacyTitle>
              <PrivacySection>
                Descriptify may allow you to provide comments, ideas,
                suggestions, or other content (“User Content”) submitted through
                or to the Site and through other social media applications,
                email, telephone or mail. You are solely responsible for any and
                all User Content. You are strictly prohibited from providing any
                User Content that is illegal, obscene, threatening, defamatory,
                invasive of privacy, infringing of intellectual property rights,
                or in our sole discretion otherwise injurious or objectionable
                to us or to any third parties. By submitting User Content to us
                you represent and warrant that the User Content is original to
                you and that no other party has any rights to the User Content.
                By submitting any User Content to Descriptify you automatically
                grant to Descriptify and its successors, assigns and licensees a
                perpetual, royalty-free right and license to use and modify such
                User Content, without any liability whatsoever.
              </PrivacySection>
              <PrivacyTitle>Links to Other Websites</PrivacyTitle>
              <PrivacySection>
                The Site may contain links to third-party web sites or services
                that are not owned or controlled by Descriptify. You acknowledge
                that we have no control over, and assume no responsibility for,
                the content, privacy policies, or practices of any third party
                web sites or services. You further acknowledge and agree that
                Descriptify shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such
                websites or services. We strongly advise you to read the terms
                and conditions and privacy policies of any third-party web sites
                or services that you visit.
              </PrivacySection>
              <PrivacyTitle>
                Disclaimers and Limitation of Liability
              </PrivacyTitle>
              <PrivacySection>
                The Site is provided on an AS IS and AS AVAILABLE basis without
                any representation or endorsement made and without warranty of
                any kind whether expressed or implied, including but not limited
                to the implied warranties of satisfactory quality, fitness for a
                particular purpose, non-infringement, compatibility, security
                and accuracy.
              </PrivacySection>
              <PrivacySection>
                To the extent permitted by law, Descriptify, its principals,
                directors, officers shareholders, partners, employees,
                contractors, suppliers or agents shall not be held liable for
                any indirect or consequential loss or damage whatsoever
                (including without limitation loss of business, opportunity,
                data, profits) arising out of or in connection with the use of
                the Site. Descriptify makes no warranty that the functionality
                of the Site will be uninterrupted or error free, that defects
                will be corrected or that the Website or the server that makes
                it available are free of viruses or anything else which may be
                harmful or destructive.
              </PrivacySection>
              <PrivacySection>
                To the fullest extent permitted by law, your sole remedy for any
                claim for damages arising out of or related to use of the Site
                and your purchase or use of the Products shall be limited to a
                refund of the purchase price paid for the Product in question.
              </PrivacySection>
              <PrivacyTitle>Indemnity</PrivacyTitle>
              <PrivacySection>
                You agree to indemnify and hold Descriptify, its principals,
                directors, officers, shareholders, partners, employees,
                contractors, suppliers or agents harmless from and against all
                liabilities, legal fees, damages, losses, costs and other
                expenses in relation to any claims or actions brought against us
                arising out of any breach by you of these Terms or other
                liabilities arising out of your use of the Site.
              </PrivacySection>
              <PrivacyTitle>Exclusions and Limitations</PrivacyTitle>
              <PrivacySection>
                Some jurisdictions do not allow the exclusion of certain
                warranties, representations and conditions or the limitation or
                exclusion of liability for incidental or consequential damages.
                Accordingly, some of the above limitations or exclusions may not
                apply to you. In the event the foregoing exclusions or
                limitation of liability is held to be unenforceable for any
                reason, then our maximum liability for any of the foregoing
                types of damages shall be limited to the amount that we receive
                for the transaction that gave rise to the claim.
              </PrivacySection>
              <PrivacyTitle>Severance</PrivacyTitle>
              <PrivacySection>
                If any provision of these Terms should be determined to be
                invalid, illegal or unenforceable for any reason by any court of
                competent jurisdiction then such provision shall be severed and
                the remaining Terms shall survive and remain in full force and
                effect and continue to be binding and enforceable.
              </PrivacySection>
              <PrivacyTitle>Governing Law</PrivacyTitle>
              <PrivacySection>
                These Terms and Conditions shall be governed by and construed in
                accordance with the law of British Columbia, Canada and you
                hereby submit to the exclusive jurisdiction of the British
                Columbia courts.
              </PrivacySection>
              <PrivacyTitle>Changes</PrivacyTitle>
              <PrivacySection>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material we
                will make reasonable efforts to provide at least 30 days' notice
                prior to any new terms taking effect. What constitutes a
                material change will be determined at our sole discretion. By
                continuing to use the Site after such revisions become
                effective, you agree to be bound by the revised Terms. If you do
                not agree to the new Terms, in whole or in part, please stop
                using the Site.
              </PrivacySection>
              <PrivacySection>
                For any further information please email: support@Descriptify.co
              </PrivacySection>
              <Button
                text="SIGN UP NOW"
                type="main"
                onClick={() => this.props.history.push("/")}
                buttonStyle=""
                fontStyle="font-size: 20px;"
                fixedWidth
              />
            </PrivacyContainer>
          </Content>
        </ContentWhite>

        <LandingFooter />
      </Wrapper>
    );
  }
}

export default withRouter(Terms);
