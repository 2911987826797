import React, { Component } from "react";
import { badgeChoices } from "modules/Badges";
import Button from "components/Button";
import styled from "styled-components";
import { theme } from "modules/Theme";
import { withRouter } from "react-router-dom";
import Input from "components/Input";

export const Icon = styled.img`
  height: 45px;
  width: 45px;
  margin: 12px;
  @media screen and (max-width: 1400px) {
    height: 40px;
    width: 40px;
  }
  @media screen and (max-width: 1000px) {
    height: 30px;
    width: 30px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 65px;
  margin: 8px;
  border-radius: 50%;
  cursor: pointer;
  background: ${p => (p.selected ? theme.colors.main : theme.colors.lightGrey)};
  &:hover {
    background: ${p =>
      p.selected ? theme.colors.mainDark : theme.colors.medGrey};
  }
  @media screen and (max-width: 1400px) {
    height: 60px;
    width: 60px;
  }
  @media screen and (max-width: 1000px) {
    height: 50px;
    width: 50px;
  }
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${theme.colors.main};
`;

export const LightBold = styled.span`
  font-weight: 400;
`;

export const Subtitle = styled.div`
  font-size: 25px;
  font-weight: 300;
  ${props =>
    props.link &&
    `
    color: ${theme.colors.main};
    cursor: pointer;
    &:hover{
      color: ${theme.colors.mainDark};
    }
  `}
`;

export const BadgesRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 9999;
`;

export const ButtonRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  font-size: 24px;
  padding: 20px;
  width: 250px;
  height: 60px;
`;

class BadgeSelect extends Component {
  state = { badge: null, label: null, error: null };

  createBadge = () => {
    if (!this.state.label) {
      this.setState({ error: "Please enter valid badge label" });
    } else {
      this.props.createBadge(this.state.badge, this.state.label);
    }
  };

  render() {
    return (
      <Wrapper>
        <Title>Create A New Badge</Title>
        <Subtitle>Select the icon you want for your new badge</Subtitle>
        <BadgesRow>
          {badgeChoices.map(badge => {
            const iconPrefix =
              "https://dc-imports.s3.ca-central-1.amazonaws.com/descriptify";
            return (
              <IconWrapper
                selected={badge === this.state.badge}
                onClick={() => this.setState({ badge: badge })}
              >
                <Icon src={`${iconPrefix}/${badge}`} />
              </IconWrapper>
            );
          })}
        </BadgesRow>

        <React.Fragment>
          <Subtitle>
            Add a label for your badge, like <LightBold>High Quality</LightBold>{" "}
            or <LightBold>Fast Shipping</LightBold>
          </Subtitle>
          <ButtonRow>
            <Input
              value={this.state.label}
              onChange={e => this.setState({ label: e.target.value })}
              placeholder="New Badge Label"
              error={this.state.error}
              extraStyle="padding: 15px; height: 60px; font-size: 20px; text-align: center;"
              marginRight
            />
            <StyledButton
              text="Create Badge"
              type={this.state.badge ? "main" : "main-light"}
              isFetching={this.props.isCreatingBadge}
              onClick={this.createBadge}
              disabled={!this.state.badge}
            />
          </ButtonRow>
        </React.Fragment>

        <Subtitle style={{ marginTop: "30px" }} link onClick={this.props.hide}>
          Return To Dashboard
        </Subtitle>
      </Wrapper>
    );
  }
}

export default withRouter(BadgeSelect);
