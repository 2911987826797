import React, { Component } from "react";

import styled from "styled-components";

import { theme } from "modules/Theme";

import caretUp from "images/caret-up.png";
import caretDown from "images/caret-down.png";
import checkIcon from "images/check-icon-white.png";

import { Column } from "components/Layout";

export const Dropdown = styled.div`
  width: ${p => p.width || "180px"};
  position: relative;
  margin-right: 15px;
  @media screen and (max-width: 800px) {
    width: calc(100% / 2);
    margin-right: 0px;
    ${p => p.mobileRightMargin && "margin-right: 5px;"};
  }
`;

export const Header = styled.div`
  padding: 5px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  cursor: pointer;
  &:hover {
    border: 1px solid #c4cdcc;
  }
  @media screen and (max-width: 800px) {
    padding: 0px;
  }
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #abb3b2;
  background: white;
  position: absolute;
  top: 58px;
  z-index: 1000;
  overflow-y: auto;
  height: 500px;
`;

export const Option = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.medDarkGrey};
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.lightBorder};
  padding: 12px;
  width: 100%;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
  ${p =>
    p.selected &&
    `
  color: #39a698;
  font-weight: 700;
  `}
`;

export const Caret = styled.img`
  height: 16px;
  opacity: 0.3;
  margin-left: 10px;
  @media screen and (max-width: 800px) {
    height: 14px;
  }
`;

export const CurrentCategory = styled.div`
  padding: 10px 15px 10px 15px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.lightBorder};
  background: white;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CurrentCategoryText = styled.div`
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  color: ${p => (p.green ? theme.colors.main : theme.colors.medDarkGrey)};
`;

export const CurrentCategorySubText = styled.div`
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  color: ${p => (p.active ? theme.colors.main : theme.colors.medGrey)};
  margin-top: 3px;
`;

export const CheckIcon = styled.img`
  height: 20px;
  width: 20px;
  background: ${theme.colors.main};
  padding: 4px;
  border-radius: 50px;
`;

class SelectDropdown extends Component {
  state = { expanded: false };

  chooseOption = value => {
    this.setState({ expanded: false });
    this.props.onClick(value);
  };

  render() {
    let current = "default";

    let optionCount = 0;

    this.props.options.map(option => {
      if (option.selected) {
        optionCount += 1;
        current = option.text;
      }
    });

    let activeFilters = "Click to edit";

    if (optionCount > 1) {
      activeFilters = `${optionCount} Active Filters`;
    } else if (optionCount === 1) {
      activeFilters = current;
    }

    return (
      <Dropdown
        width={this.props.width}
        mobileRightMargin={this.props.mobileRightMargin}
      >
        <CurrentCategory
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          <Column style={{ width: "100%" }}>
            <CurrentCategoryText green={this.props.current}>
              {this.props.current || this.props.name}
            </CurrentCategoryText>

            {/* <CurrentCategorySubText active={this.props.current !== ""}>
              {this.props.current}
            </CurrentCategorySubText> */}
            {/* <CurrentCategorySubText
              active={
                (this.props.toggle && optionCount > 0) ||
                (!this.props.toggle && !current === "default")
              }
            >
              {this.props.toggle ? activeFilters : current}
            </CurrentCategorySubText> */}
          </Column>
          <Caret src={this.state.expanded ? caretUp : caretDown} />
        </CurrentCategory>

        {this.state.expanded && (
          <OptionsWrapper>
            {this.props.options.map(option => (
              <Option
                key={option.value}
                selected={option.selected}
                onClick={() => this.chooseOption(option.value)}
              >
                <span>{option.label}</span>
                {option.selected && <CheckIcon src={checkIcon} />}
              </Option>
            ))}
          </OptionsWrapper>
        )}
      </Dropdown>
    );
  }
}

export default SelectDropdown;
